import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';

const initialState = {
    deliveryAddressLoader: false,
};

const settingsStateSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        deliveryAddressLoaderSet: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.deliveryAddressLoader = payload;
        },
    },
});

export const deliveryAddressLoaderState = (store: AppState) =>
    store.settings.deliveryAddressLoader;

export const {deliveryAddressLoaderSet} = settingsStateSlice.actions;

export default settingsStateSlice.reducer;
