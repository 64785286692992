import {AppState} from 'store/customer/storeSetup';
import {MaterialDetailsInterface} from 'components/customer/Materials/MaterialDetails';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';

export const materialDetailsMapping = (
    state: AppState,
    props: MaterialDetailsInterface
) => ({
    material: getMaterial(state, props.materialType),
    door: getDoor(state),
    edge: getEdge(state, props.materialType),
});
