import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';

export interface RoomLoaderInterface {
    isRoomLoading: boolean;
    isHardwareSelectionLoading: boolean;
    isSupplyDefaultsLoading: boolean;
    isColourSelectionsLoading: boolean;
    isProductSizeDefaultsLoading: boolean;
    isGapSizeDefaultsLoading: boolean;
    isMaterialLoading: boolean;
}

const initialState: RoomLoaderInterface = {
    isRoomLoading: false,
    isHardwareSelectionLoading: false,
    isSupplyDefaultsLoading: false,
    isColourSelectionsLoading: false,
    isProductSizeDefaultsLoading: false,
    isGapSizeDefaultsLoading: false,
    isMaterialLoading: false,
};

export const roomLoaderSlice = createSlice({
    name: 'roomLoader',
    initialState,
    reducers: {
        setIsRoomLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isRoomLoading = payload;
        },
        setIsHardwareSelectionLoading: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isHardwareSelectionLoading = payload;
        },
        setIsSupplyDefaultsLoading: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isSupplyDefaultsLoading = payload;
        },
        setIsColourSelectionsLoading: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isColourSelectionsLoading = payload;
        },
        setIsProductSizeDefaultsLoading: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isProductSizeDefaultsLoading = payload;
        },
        setIsGapSizeDefaultsLoadingLoading: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isGapSizeDefaultsLoading = payload;
        },
        setIsMaterialLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isMaterialLoading = payload;
        },
    },
});

export const getRoomLoader = (store: AppState) => store.roomLoader;
export const getIsColourSelectionsLoading = (
    store: AppState,
    activeColourSelections: boolean
) =>
    store.roomLoader.isColourSelectionsLoading ||
    (!store.materials?.materials[0]?.defaultLoadedCarcase &&
        activeColourSelections);

export const getAnyLoadingActive = (
    store: AppState,
    activeColourSelections: boolean
) => {
    const {
        isColourSelectionsLoading,
        isGapSizeDefaultsLoading,
        isHardwareSelectionLoading,
        isProductSizeDefaultsLoading,
        isSupplyDefaultsLoading,
    } = store.roomLoader;

    const colourSelectionsLoading =
        isColourSelectionsLoading ||
        (!store.materials?.materials[0]?.defaultLoadedCarcase &&
            activeColourSelections);

    return (
        colourSelectionsLoading ||
        isGapSizeDefaultsLoading ||
        isHardwareSelectionLoading ||
        isProductSizeDefaultsLoading ||
        isSupplyDefaultsLoading
    );
};

export const {
    setIsRoomLoading,
    setIsHardwareSelectionLoading,
    setIsSupplyDefaultsLoading,
    setIsColourSelectionsLoading,
    setIsProductSizeDefaultsLoading,
    setIsGapSizeDefaultsLoadingLoading,
    setIsMaterialLoading,
} = roomLoaderSlice.actions;
export default roomLoaderSlice.reducer;
