import {Middleware, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {
    dimensionErrorSet,
    sideSet,
} from 'components/customer/BTM/store/btmSlice';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import {
    getValidationRules,
    Rule,
} from 'components/customer/BTM/helper/sizeValidationMap';
import Excel from 'shared/Excel';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {getJoinsOnSide} from 'components/customer/BTM/helper/joinSideMap';
import {Join} from 'components/customer/BTM/entity/Join';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';

/**
 * Takes Rule array and returns array of error messages
 *
 * @param {boolean[]} checks Whether the corrensponding rules are passing
 * @param {Rule[]} rules Rule object with message object
 * @param {object} scope Object that contains all the information for message formation
 * @return {string[]} Array of error messages
 */
export const getMessages = (
    checks: boolean[],
    rules: Rule[],
    scope: object
) => {
    const messages = checks.map((check, index) => {
        if (!check) {
            const rule = rules[Number(index)];

            const message = rule.message;

            let text = message.text;
            Object.keys(message)
                .filter((key) => key != 'text')
                .forEach((key) => {
                    const value = Excel.calculate(message[String(key)], scope);

                    text = text.replace(`{${key}}`, String(value));
                });

            return text;
        }
    });

    return messages.filter((val) => typeof val !== 'undefined');
};

interface RuleAndScope {
    validation: ReturnType<typeof getValidationRules>;
    scope: object;
}

/**
 * This function simply takes bench information and returns back
 * validation rules and scope for calculation based on side
 *
 * @param {Side} side Side for which rules needs to be extracted
 * @param {Shape} shape shape of the bench
 * @param {Join[]} joins All of the join objects
 * @param {BenchtopMaterial} material Selected material data
 * @param {number[]} dimensions Dimension of the bench
 * @param {number} length length of the current side
 * @param {number} maxLength Max allowed length by the selected material
 * @param {number} maxDepth Max allowed depth by the selected material
 * @return {RuleAndScope}
 */
export const getRuleAndScope = (
    side: Side,
    shape: Shape,
    joins: Join[],
    material: BenchtopMaterial,
    dimensions: number[],
    length: number,
    maxLength: number,
    maxDepth: number
): RuleAndScope => {
    const validation = getValidationRules(shape, side);
    let selectedJoins: Join[] = [];

    if (shape != Shape.SQR) {
        selectedJoins = getJoinsOnSide(shape, side, joins);
    }

    let scope: {[key: string]: number | BenchtopMaterial | Join} = {
        maxLength,
        maxDepth,
        material,
        length,
    };

    if (shape == Shape.ANG) {
        const [width, right, innerHorizontal, innerVertical, bottom, height] =
            dimensions;

        scope = {
            ...scope,
            width,
            right,
            innerHorizontal,
            innerVertical,
            bottom,
            height,
        };

        if (selectedJoins.length > 0) {
            scope.join = selectedJoins[0];
        }
    } else if (shape == Shape.USHAPE) {
        const [
            width,
            rightHeight,
            rightBottom,
            rightInner,
            inner,
            leftInner,
            leftBottom,
            leftHeight,
        ] = dimensions;
        scope = {
            ...scope,
            width,
            rightHeight,
            rightBottom,
            rightInner,
            inner,
            leftInner,
            leftBottom,
            leftHeight,
        };

        if (side == Side.A) {
            const leftJoin = selectedJoins.find(
                (join) => join.side == JoinSide.LEFT
            );
            const rightJoin = selectedJoins.find(
                (join) => join.side == JoinSide.RIGHT
            );

            if (leftJoin) {
                scope.leftJoin = leftJoin;
            }

            if (rightJoin) {
                scope.rightJoin = rightJoin;
            }
        } else {
            if (selectedJoins.length > 0) {
                scope.join = selectedJoins[0];
            }
        }
    }

    return {
        validation,
        scope,
    };
};

export const getDimensionError = (
    side: Side,
    shape: Shape,
    joins: Join[],
    material: BenchtopMaterial,
    dimensions: number[],
    length: number
) => {
    const {validation, scope} = getRuleAndScope(
        side,
        shape,
        joins,
        material,
        dimensions,
        length,
        material.max_length,
        material.max_depth
    );

    if (validation) {
        const checks = validation.rules.map((rule) => {
            try {
                const check = Excel.calculate(rule.check, scope);

                return check as boolean;
            } catch (e) {
                throw e;
            }
        });

        if (!checks.every(Boolean)) {
            const messages = getMessages(checks, validation.rules, scope);

            return messages.join(',\n');
        }
    }

    return null;
};

export const dimensionUpdateMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<number, string, {index: Side}>) => {
        if (action.type == sideSet.type) {
            const state = store.getState();
            const shape = state.btm.type;
            const dimension = state.btm.dimension;
            const material = state.btm.material;
            const joins = state.btm.joins;
            const side = action?.meta?.index;
            const length = action.payload;

            const {validation, scope} = getRuleAndScope(
                side,
                shape.type,
                joins,
                material,
                dimension,
                length,
                material.max_length,
                material.max_depth
            );

            if (validation) {
                const checks = validation.rules.map((rule) => {
                    const check = Excel.calculate(rule.check, scope);

                    return check as boolean;
                });

                if (!checks.every(Boolean)) {
                    const messages = getMessages(
                        checks,
                        validation.rules,
                        scope
                    );

                    store.dispatch(
                        dimensionErrorSet(messages.join(',\n'), side)
                    );
                    throw new Error(messages.join(',\n'));
                } else {
                    store.dispatch(dimensionErrorSet('', side));
                }

                if (validation.updates && validation.updates.length > 0) {
                    validation.updates.forEach((update) => {
                        const value = Excel.calculate(update.value, scope);

                        store.dispatch(sideSet(Number(value), update.side));
                    });
                }
            }

            if (isNaN(action.payload)) {
                action.payload = 0;
            }
        }

        return next(action);
    };
