import {MaterialType} from 'components/customer/Materials/entity';
import {
    edgeCarcasePageSet,
    edgeCarcasesAdd,
    edgeCarcasesSet,
    edgeMaterialPageSet,
    edgeMaterialsAdd,
    edgeMaterialsSet,
} from 'components/customer/Materials/store/materialSlice';

export const getEdgesAdd = (type: MaterialType) => {
    if (type === MaterialType.EXTERIOR) return edgeMaterialsAdd;
    return edgeCarcasesAdd;
};

export const getEdgesSet = (type: MaterialType) => {
    if (type === MaterialType.EXTERIOR) return edgeMaterialsSet;
    return edgeCarcasesSet;
};

export const getEdgePageSet = (type: MaterialType) => {
    if (type === MaterialType.EXTERIOR) return edgeMaterialPageSet;
    return edgeCarcasePageSet;
};
