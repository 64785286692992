import {AppApi} from 'store/customer/api';
import {ExtraVariation, Job} from 'components/customer/Job/entity/Job';

export type MinimumUsage = {
    materialType: string;
    id: number;
    jobId: number;
    jobCabinetIds: string;
    benchtopIds: string | null;
    materialId: number;
    usage: number;
    calculatedSheet: number;
    rolloverUsage: number;
    minimumUsage: number;
    areaCost: number;
    type: string | null;
    doubleSidedCost: number;
    cost: number;
    measurement: string;
    products: string[];
    lengthCost: number;
    edgeId: number;
};

export interface DiscountItem {
    name: string;
    cost: number;
}

export interface BreakdownItem {
    name: string;
    cost: number;
    collapse?: boolean;
    items?: DiscountItem[];
}

type CouponDetail = {
    invalid: boolean;
    coupon_code: string;
    expiry_date: {
        date: string;
    };
};

type JobCost = {
    customerMinCharge: number;
    totalVariationCost: number;
    customerDiscount: string;
    supplierDiscount: number;
    freightCost: number;
    jobSubtotal: number;
    tax: number;
    totalJobCostExclTax: number;
    totalJobCost: number;
    totalMinUsage: number;
    supplierCommision: number;
    payPalSurcharge: number;
    maxJobSubtotal: number;
    coupon: null;
    couponDetails: CouponDetail;
    pricingMessages?: string[];
    pricingBreakdown?: BreakdownItem[];
    gfp_markup: number;
    minUsage: MinimumUsage[];
    minUsageEdge: MinimumUsage[];
    additionServicesCost: number;
    jobMinChargeFromCustMin: number;
    supplierCommission: number;
};

interface JobResponse {
    displayId: number;
    jobId: number;
    messages: string[];
}

const JobApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobCost: builder.query<JobCost | null, {jobId: number}>({
            query: ({jobId}) => `job/${jobId}/cost`,
            transformResponse: (response: {cost: null}) => response.cost,
            providesTags: (response, error, {jobId}) => [
                {type: 'JobCost', jobId},
            ],
        }),
        // NOTE: This api can probably be used to fetch cost and products list
        // without additional network request. Sorry Nav. I should have thought
        // about this sooner. Will create a tech debt ticket right after to
        // consolidate all job and job related network request and data as
        // Updating jobContext is probably out of context for this ticket.
        getJob: builder.query<Job, {jobId: number}>({
            query: ({jobId}) => `jobs/${jobId}`,
            providesTags: (response, error, {jobId}) => [{type: 'Job', jobId}],
            transformResponse: (response: {data: Job}) => {
                const job = response.data;

                if (Array.isArray(job.rooms)) {
                    job.rooms = job.rooms.map((room, index) => {
                        room.roomNumber = index + 1;
                        if (room.jobCabinets) {
                            room.jobCabinets.map((product, productIndex) => {
                                product.productNumber = `${index + 1}-${
                                    productIndex + 1
                                }`;
                            });
                        }

                        if (room.jobBenchtops) {
                            room.jobBenchtops.map((product, productIndex) => {
                                product.productNumber = `${index + 1}-${
                                    productIndex + 1
                                }`;
                            });
                        }

                        return room;
                    });
                }

                return job;
            },
        }),
        getJobStatusStatistics: builder.query<string[], void>({
            query: () => 'jobs/statistics',
            providesTags: ['JobStatus'],
            transformResponse: (response: {data: string[]}) => response.data,
        }),
        jobReset: builder.mutation<void, {jobId: number; isReset?: boolean}>({
            query: ({jobId, isReset = true}) => ({
                url: `jobs/${jobId}/back-to-processing?isReset=${String(
                    isReset
                )}`,
                method: 'POST',
            }),
            invalidatesTags: ['Job', 'Variations', 'BTMBenches', 'JobCost'],
        }),
        confirmVariations: builder.mutation<void, {jobId: number}>({
            query: ({jobId}) => ({
                url: `jobs/${jobId}/updateJob`,
                method: 'POST',
            }),
        }),
        getExtraVariations: builder.query<ExtraVariation[], {jobId: number}>({
            query: ({jobId}) => `/job/extra-variations/${jobId}`,
            transformResponse: (response: {data: ExtraVariation[]}) => {
                return response.data;
            },
            providesTags: () => [{type: 'Variations'}],
        }),
        removeExtraVariation: builder.mutation<
            void,
            {
                data: {
                    ids: number[];
                };
            }
        >({
            query: ({data}) => ({
                url: `/job/remove-extra-variations`,
                method: 'POST',
                body: {
                    data: data,
                },
            }),
        }),
        updateRequestedDeliveryDate: builder.mutation<
            void,
            {jobId: number; requestedDeliveryDate: string}
        >({
            query: ({jobId, requestedDeliveryDate}) => ({
                url: `jobs/${jobId}/requested-delivery-date`,
                method: 'PUT',
                body: {
                    requestedDeliveryDate,
                },
            }),
            invalidatesTags: ['Job'],
        }),
        jobSave: builder.mutation<
            JobResponse,
            {data: FormData; jobId?: number}
        >({
            query: ({data, jobId}) => ({
                url:
                    typeof jobId === 'undefined'
                        ? 'jobs'
                        : `jobs/${jobId - 10000}`,
                method: typeof jobId === 'undefined' ? 'POST' : 'PUT',
                body: data,
            }),
            transformResponse: (response: JobResponse) => response,
            invalidatesTags: ['Job'],
        }),
    }),
});

// remove this once we properly implement rtk query to invalidate tags
export const invalidateJobCost = () => JobApi.util.invalidateTags(['JobCost']);
export const invalidateJob = () => JobApi.util.invalidateTags(['Job']);
export const invalidateVariations = () =>
    JobApi.util.invalidateTags(['Job', 'Variations', 'BTMBenches', 'JobCost']);

export const {
    useGetJobCostQuery,
    useJobResetMutation,
    useConfirmVariationsMutation,
    useGetJobStatusStatisticsQuery,
    useLazyGetExtraVariationsQuery,
    useRemoveExtraVariationMutation,
    useUpdateRequestedDeliveryDateMutation,
    useLazyGetJobQuery,
    useJobSaveMutation,
} = JobApi;
