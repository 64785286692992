import {configureStore, PreloadedState} from '@reduxjs/toolkit';

import {apiMiddleware} from 'store/customer/api';

import {doorStyleMiddleware} from 'components/customer/Room/store/middleware';
import {rtkQueryErrorMiddleware} from 'components/customer/Auth/store/middleware/rtkQueryErrorMiddleware';
import {materialTutorialMiddleware} from 'components/customer/Materials/store/middleware/materialTutorialMiddleware';
import {btmMiddleware} from 'components/customer/BTM/store/middleware';
import {qfpMiddlewares} from 'components/customer/QFPRedux/store/middleware/qfpMiddleware';
import {applicationListenerMiddleware} from 'store/customer/listenerMiddlewareSetup';

import {createReducer} from 'store/customer/reducers';

const reducer = createReducer();
export type AppState = ReturnType<typeof reducer>;

export const setupStore = (preloadedState?: PreloadedState<AppState>) => {
    return configureStore({
        preloadedState,
        reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            })
                .concat(apiMiddleware)
                .concat(doorStyleMiddleware)
                .concat(rtkQueryErrorMiddleware)
                .concat(materialTutorialMiddleware)
                .concat(applicationListenerMiddleware.middleware)
                .concat(qfpMiddlewares)
                .concat(btmMiddleware),
    });
};

export const store = setupStore();
export type AppDispatch = typeof store.dispatch;
