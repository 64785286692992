import {AppApi} from 'store/customer/api';
import {Type} from 'components/customer/QFPRedux/entity';

export const QfpApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getProductType: builder.query<Type[], void>({
            query: () => 'cabinets/quickflatproducts',
            transformResponse: (response: {
                quick_flat_product_types: Type[];
            }) => {
                return response.quick_flat_product_types.sort((a, b) => {
                    if (a.order && b.order) {
                        return a.order - b.order;
                    } else if (a.order) {
                        return -1;
                    } else if (b.order) {
                        return 1;
                    } else {
                        return a.name.localeCompare(b.name);
                    }
                });
            },
            providesTags: [{type: 'QFPTypes'}],
        }),
        getCost: builder.query<number, object>({
            query: (body) => ({
                url: 'cabinets/jobcabinet/cost',
                method: 'POST',
                body,
            }),
            transformResponse: (response: {job_cabinet_cost: number}) =>
                response.job_cabinet_cost,
        }),
    }),
});

export const {useGetProductTypeQuery, useLazyGetCostQuery} = QfpApi;
