import {MaterialType} from 'components/customer/Materials/entity';
import {
    carcasePageSet,
    carcasesAdd,
    carcasesSet,
    materialPageSet,
    materialsAdd,
    materialsSet,
} from 'components/customer/Materials/store/materialSlice';

export const getMaterialsAdd = (type: MaterialType) => {
    if (type === MaterialType.EXTERIOR) return materialsAdd;
    return carcasesAdd;
};

export const getMaterialsSet = (type: MaterialType) => {
    if (type === MaterialType.EXTERIOR) return materialsSet;
    return carcasesSet;
};

export const getMaterialPageSet = (type: MaterialType) => {
    if (type === MaterialType.EXTERIOR) return materialPageSet;
    return carcasePageSet;
};
