import {Page} from 'store/customer/entity/Page';
import {Configuration} from 'store/customer/entity/Configuration';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppApi} from 'store/customer/api';
import {AppState} from 'store/customer/storeSetup';

interface RawConfiguration {
    BTD_HOST: string;
    DEV_SITE_NAME: string;
    GA_KEY?: string;
    GA_KEYS?: string;
    GTM_ID?: string;
    GTM_IDS?: string;
}

interface ApplicationInterface {
    page?: Page;
    reloadScript?: boolean;
    // NOTE: When updates are possibly unified, this can go away
    // Update 1. Message from manufacturers
    // Update 2. New Room assistant message
    updateMessageShown: boolean;
    showUpdateMessage: boolean;
}

const initialState: ApplicationInterface = {
    page: Page.PRODUCT,
    reloadScript: false,
    updateMessageShown: true,
    showUpdateMessage: false,
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        reloadScriptSet: (state, {payload}: PayloadAction<boolean>) => {
            state.reloadScript = payload;
        },
        pageSet: (state, {payload}: PayloadAction<Page>) => {
            state.page = payload;
        },
        updateMessageShownSet: (state, {payload}: PayloadAction<boolean>) => {
            state.updateMessageShown = payload;
        },
        showUpdateMessageSet: (state, {payload}: PayloadAction<boolean>) => {
            state.showUpdateMessage = payload;
        },
    },
});

export const selectReloadScript = (store: AppState) =>
    store.application.reloadScript;
export const selectPage = (store: AppState) => store.application.page;
export const selectUpdateMessageShown = (store: AppState) =>
    store.application.updateMessageShown;
export const selectShowUpdateMessage = (store: AppState) => {
    return store.application.showUpdateMessage;
};

export const {
    pageSet,
    reloadScriptSet,
    updateMessageShownSet,
    showUpdateMessageSet,
} = applicationSlice.actions;

export default applicationSlice.reducer;

interface KeySet {
    key:
        | Configuration['googleAnalyticsKey']
        | Configuration['googleTagManagerId'];
    keys:
        | Configuration['googleAnalyticsKeys']
        | Configuration['googleTagManagerIds'];
}

const isKeyList = (k: unknown): k is Record<string, string> => {
    return typeof k == 'object';
};

const getGaKeys = (
    keyInput: string | null,
    keysInput: string | null
): KeySet => {
    let key = null;
    let keys = null;

    const parsedKeys: unknown = JSON.parse(keysInput);

    if (typeof parsedKeys == 'string') {
        key = keysInput;
        keys = null;
    } else {
        key = typeof keyInput == 'string' && keyInput != '' ? keyInput : null;

        if (isKeyList(parsedKeys)) {
            keys = parsedKeys;
        }
    }

    return {key, keys};
};

const ConfigurationApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getConfiguration: builder.query<Configuration, void>({
            query: () => '../configuration/',
            transformResponse: (response: RawConfiguration) => {
                const {key: gaKey, keys: gaKeys} = getGaKeys(
                    response.hasOwnProperty('GA_KEY') ? response.GA_KEY : null,
                    response.hasOwnProperty('GA_KEYS') ? response.GA_KEYS : null
                );
                const {key: gtmId, keys: gtmIds} = getGaKeys(
                    response.hasOwnProperty('GTM_ID') ? response.GTM_ID : null,
                    response.hasOwnProperty('GTM_IDS') ? response.GTM_IDS : null
                );

                return {
                    environmentName: response.DEV_SITE_NAME,
                    btdEmbedURL: response.BTD_HOST,
                    googleAnalyticsKey: gaKey,
                    googleAnalyticsKeys: gaKeys,
                    googleTagManagerId: gtmId,
                    googleTagManagerIds: gtmIds,
                };
            },
            providesTags: ['Configuration'],
        }),
    }),
});

export const {useGetConfigurationQuery} = ConfigurationApi;
