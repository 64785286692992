import {Middleware, PayloadAction} from '@reduxjs/toolkit';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {
    clearButtJoins,
    materialSet,
} from 'components/customer/BTM/store/btmSlice';
import {AppState} from 'store/customer/storeSetup';

export const materialSetMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<BenchtopMaterial, string, {initBench: boolean}>) => {
        if (action.type == materialSet.type) {
            const state = store.getState();
            const material = state.btm.material;
            if (material && material.id != action.payload.id) {
                if (material.allowButtJoin && !action.payload.allowButtJoin) {
                    store.dispatch(clearButtJoins());
                }
            }
        }

        return next(action);
    };
