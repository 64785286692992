import {Middleware, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {
    showRoomTutorialSet,
    showProductTutorialSet,
} from 'components/customer/Materials/store/materialSlice';
import {setMaterialTutorialPreference} from 'components/customer/Materials/helper';
import {Page} from 'store/customer/entity/Page';

export const materialTutorialMiddleware: Middleware<unknown, AppState> =
    () => (next) => (action: PayloadAction<boolean>) => {
        if (action.type === showProductTutorialSet.type) {
            setMaterialTutorialPreference(Page.PRODUCT, action.payload);
        } else if (action.type === showRoomTutorialSet.type) {
            setMaterialTutorialPreference(Page.ROOM, action.payload);
        }

        return next(action);
    };
