import {isRejectedWithValue, Middleware, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {apiReducerPath} from 'store/customer/api';
import {
    logoutPopupSet,
    messageSet,
    redirectToSet,
} from 'components/customer/Auth/store/authSlice';

interface ErrorResponse {
    status: number;
    data: object;
}

export const rtkQueryErrorMiddleware: Middleware<unknown, AppState> =
    () => (next) => (action: PayloadAction<ErrorResponse>) => {
        if (
            isRejectedWithValue(action) &&
            action.type.match(`^${apiReducerPath}`)
        ) {
            const payload = action.payload;

            if (payload.status == 401 && payload.data?.sessionChanged == true) {
                next(logoutPopupSet(true));
                next(messageSet(payload.data?.error));
                next(redirectToSet(payload.data?.redirectTo));
            }

            const headerValue =
                action?.meta?.baseQueryMeta?.response?.headers?.get(
                    'X-CO-Authenticated'
                );

            if (headerValue == 'No') {
                next(logoutPopupSet(true));
            }
        }

        return next(action);
    };
