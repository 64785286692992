import {MaterialType} from 'components/customer/Materials/entity';
import {AppState} from 'store/customer/storeSetup';
import {
    createSelectorMaterial,
    searchCarcaseEdgeSet,
    searchCarcaseSet,
    searchExteriorEdgeSet,
    searchExteriorSet,
} from 'components/customer/Materials/store/materialSlice';
import {MaterialSearchInterface} from 'components/customer/Materials/MaterialSearch';
import {
    getMaterialPageSet,
    getMaterialsAdd,
    getMaterialsSet,
} from 'components/customer/Materials/store/setters/materialSetter';
import {selectPage} from 'store/customer/appSlice';
import {Page} from 'store/customer/entity/Page';

export const getMaterial = createSelectorMaterial((state, type) => {
    if (state && (state.material || state.carcase)) {
        if (type == MaterialType.EXTERIOR) return state.material;

        return state.carcase;
    }
});

const getMaterials = createSelectorMaterial((state, type) => {
    if (type === MaterialType.EXTERIOR) return state.materials;

    return state.carcases;
});

const getPageNumber = createSelectorMaterial((state, type) => {
    if (type === MaterialType.EXTERIOR) return state.materialPageNumber;

    return state.carcasePageNumber;
});

const setMaterialSearch = (materialType: MaterialType) => {
    if (materialType == MaterialType.EXTERIOR) return searchExteriorSet;
    return searchCarcaseSet;
};

export const setEdgeSearch = (materialType: MaterialType) => {
    if (materialType == MaterialType.EXTERIOR) return searchExteriorEdgeSet;
    return searchCarcaseEdgeSet;
};

export const materialMapping = (
    state: AppState,
    {materialType, index}: MaterialSearchInterface
) => ({
    isQFP: selectPage(state) == Page.QFP,
    material: getMaterial(state, materialType, index),
    materials: getMaterials(state, materialType, index),
    pageNumber: getPageNumber(state, materialType, index),
    materialPageSet: getMaterialPageSet(materialType),
    materialsAdd: getMaterialsAdd(materialType),
    materialsSet: getMaterialsSet(materialType),
    setEdgeSearch: setEdgeSearch(materialType),
    setMaterialSearch: setMaterialSearch(materialType),
});
