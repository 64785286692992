import {MaterialType} from 'components/customer/Materials/entity';
import {AppState} from 'store/customer/storeSetup';
import {
    createSelectorMaterial,
    selectSearch,
} from 'components/customer/Materials/store/materialSlice';
import {EdgeSearchInterface} from 'components/customer/Materials/EdgeSearch';
import {
    getEdgesAdd,
    getEdgesSet,
    getEdgePageSet,
} from 'components/customer/Materials/store/setters/edgeSetter';
import {
    getMaterial,
    setEdgeSearch,
} from 'components/customer/Materials/store/selectors/materialSelector';
import {
    getDoor,
    getIsDefaultLoaded,
} from 'components/customer/Materials/store/selectors/doorSelector';

export const getEdge = createSelectorMaterial((state, type) => {
    if (type == MaterialType.EXTERIOR) return state.edgeMaterial;

    return state.edgeCarcase;
});

const getEdges = createSelectorMaterial((state, type) => {
    if (type == MaterialType.EXTERIOR) return state.edgeMaterials;

    return state.edgeCarcases;
});

const getPageNumber = createSelectorMaterial((state, type) => {
    if (type == MaterialType.EXTERIOR) return state.edgeMaterialPageNumber;

    return state.edgeCarcasePageNumber;
});

export const edgeMapping = (
    state: AppState,
    {materialType, index}: EdgeSearchInterface
) => ({
    edge: getEdge(state, materialType, index),
    exteriorEdge: getEdge(state, MaterialType.EXTERIOR, index),
    edges: getEdges(state, materialType, index),
    pageNumber: getPageNumber(state, materialType, index),
    edgesAdd: getEdgesAdd(materialType),
    edgesSet: getEdgesSet(materialType),
    edgePageSet: getEdgePageSet(materialType),
    material: getMaterial(state, materialType, index),
    door: getDoor(state, materialType, index),
    isDefaultLoaded: getIsDefaultLoaded(state, materialType, index),
    searchEdge: selectSearch(state, materialType, index),
    setSearchEdge: setEdgeSearch(materialType),
});
