import {MaterialType} from 'components/customer/Materials/entity';
import {AppState} from 'store/customer/storeSetup';
import {DoorSearchInterface} from 'components/customer/Materials/DoorSearch';
import {createSelectorMaterial} from 'components/customer/Materials/store/materialSlice';
import {
    getMaterial,
    setEdgeSearch,
} from 'components/customer/Materials/store/selectors/materialSelector';

export const getDoor = createSelectorMaterial((state) => state && state.door);

const getDoors = createSelectorMaterial((state) => state && state.doors);

const getDoorPageNumber = createSelectorMaterial(
    (state) => state && state.doorPageNumber
);

export const getPendingDoor = createSelectorMaterial(
    (state) => state && state.pendingDoor
);

const getSearchDoor = createSelectorMaterial(
    (state) => state && state.searchDoor
);

export const getIsDefaultLoaded = createSelectorMaterial((state, type) => {
    if (type == MaterialType.EXTERIOR)
        return state && state.defaultLoadedExterior;
    return state && state.defaultLoadedCarcase;
});

export const doorMapping = (
    state: AppState,
    {materialType, index}: DoorSearchInterface
) => ({
    door: getDoor(state, materialType, index),
    doors: getDoors(state, materialType, index),
    pageNumber: getDoorPageNumber(state, materialType, index),
    isDefaultLoaded: getIsDefaultLoaded(state, materialType, index),
    searchDoor: getSearchDoor(state, MaterialType.EXTERIOR, index),
    setEdgeSearch: setEdgeSearch(materialType),
    material: getMaterial(state, materialType, index),
});
