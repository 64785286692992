import {CabinetTop} from 'shared/types/CabinetTop';
import {GapSize} from 'shared/types/GapSize';
import {HingeStyle} from 'shared/types/HingeStyle';
import {ProductSize} from 'shared/types/ProductSize';
import {AppApi} from 'store/customer/api';

const RoomApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getHingeStylesRoom: builder.query<HingeStyle[], void>({
            query: () => 'hingeStyle/room',
            transformResponse: (response: {hingeStyles: HingeStyle[]}) =>
                response.hingeStyles,
            providesTags: ['HingeStylesRoom'],
        }),
        getCabinetTops: builder.query<CabinetTop[], void>({
            query: () => '/rooms/cabinet-tops',
            transformResponse: (response: {data: CabinetTop[]}) =>
                response.data,
            providesTags: ['CabinetTops'],
        }),
        getProductSizeDefaults: builder.query<ProductSize[], void>({
            query: () => '/rooms/product-size-defaults',
            transformResponse: (response: {data: ProductSize[]}) =>
                response.data,
            providesTags: ['ProductSizes'],
        }),
        getGapSizeDefaults: builder.query<GapSize[], void>({
            query: () => '/rooms/gap-size-defaults',
            transformResponse: (response: {data: GapSize[]}) => {
                return response.data.map((gapSize) => ({
                    ...gapSize,
                    id: Number(gapSize.id),
                }));
            },
            providesTags: ['GapSizes'],
        }),
        deleteRoom: builder.mutation<void, {id: number; jobId: number}>({
            query: ({id, jobId}) => ({
                url: `jobs/${jobId}/rooms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Job', 'Variations', 'JobCost'],
        }),
        copyRoom: builder.mutation<
            void,
            {id: number; jobId: number; copyProducts: boolean}
        >({
            query: ({id, jobId, copyProducts}) => ({
                url: `jobs/${jobId}/rooms/${id}/copy`,
                method: 'GET',
                params: copyProducts ? {} : {products: ''},
            }),
            invalidatesTags: ['Job', 'Variations', 'JobCost'],
        }),
    }),
});

export const {
    useGetHingeStylesRoomQuery,
    useGetCabinetTopsQuery,
    useGetProductSizeDefaultsQuery,
    useGetGapSizeDefaultsQuery,
    useDeleteRoomMutation,
    useCopyRoomMutation,
} = RoomApi;
