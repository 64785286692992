import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from './storeSetup';
import {Depot} from 'components/manufacturer/DepotSettings/depotApi';

export const DepotFreightOptions = {
    PICKUP: 1,
    DELIVERY: 2,
    PICKUP_AND_DELIVERY: 3,
};

interface DepotInterface {
    depotList: Depot[];
    freightOptionMethod: number;
    selectedDepot?: Depot;
}

const initialState: DepotInterface = {
    freightOptionMethod: 0,
    depotList: [],
    selectedDepot: null,
};

const depotSlice = createSlice({
    name: 'depot',
    initialState,
    reducers: {
        setDepotList: (state, {payload}: PayloadAction<Depot[]>) => {
            state.depotList = payload;
        },
        selectedDepot: (state, {payload}: PayloadAction<number>) => {
            const selected = state.depotList?.find(
                (depot) => depot.id === payload
            );

            state.selectedDepot = selected;
            state.freightOptionMethod = selected
                ? selected.freight_pickup_option
                : null;
        },
    },
});

export const depotListState = (store: AppState) => store.depot.depotList;
export const selectedDepotState = (store: AppState) =>
    store.depot.selectedDepot;

export const {selectedDepot, setDepotList} = depotSlice.actions;

export default depotSlice.reducer;
