import {ReactElement} from 'react';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';

export enum SidebarStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export enum SidebarBehaviour {
    AUTOHIDE = 'AUTOHIDE',
    TOGGLE = 'TOGGLE',
    INVISIBLE = 'INVISIBLE',
}

export enum SidebarName {
    LEFT = 'LeftSidebarV2',
    RIGHT = 'RightSidebarV2',
}

export enum SidebarBehaviourName {
    LEFT = 'LeftSidebarBehaviourV2',
    RIGHT = 'RightSidebarBehaviourV2',
}

export interface Sidebar {
    status: SidebarStatus;
    behaviour: SidebarBehaviour;
    mainContentVisible: boolean;
    softOpen: boolean;
    smallScreenBehaviour: SidebarBehaviour;
    smallScreenStatus: SidebarStatus;
}

export interface UISliceInterface {
    rightSidebar: Sidebar;
    leftSidebar: Sidebar;
    showSidebarOverlays: boolean;
    isSmallScreenLayout: boolean;
    isCartToggleVisible: boolean;
    alternateCartContent: ReactElement;
    softOpenSidebarLoaded: boolean;
}

export const storageValue = (name: string, defaultValue: string): string => {
    const sidebarValue = localStorage.getItem(name);

    if (sidebarValue == null || sidebarValue?.toLowerCase() == 'undefined') {
        return defaultValue?.toUpperCase();
    }

    return sidebarValue?.toUpperCase();
};

const leftSidebarStorage = storageValue(SidebarName.LEFT, SidebarStatus.OPEN);
const rightSidebarStorage = storageValue(SidebarName.RIGHT, SidebarStatus.OPEN);

const leftSidebarBehaviorStorage = storageValue(
    SidebarBehaviourName.LEFT,
    SidebarBehaviour.TOGGLE
);

const rightSidebarBehaviorStorage = storageValue(
    SidebarBehaviourName.RIGHT,
    SidebarBehaviour.TOGGLE
);

export const defaultRightSidebarBehavior =
    SidebarBehaviour[
        rightSidebarBehaviorStorage as keyof typeof SidebarBehaviour
    ];

export const defaultLeftSidebarBehavior =
    SidebarBehaviour[
        leftSidebarBehaviorStorage as keyof typeof SidebarBehaviour
    ];

export const defaultRightSidebar =
    SidebarStatus[rightSidebarStorage as keyof typeof SidebarStatus];

export const defaultLeftSidebar =
    SidebarStatus[leftSidebarStorage as keyof typeof SidebarStatus];

const initialState: UISliceInterface = {
    rightSidebar: {
        status: defaultRightSidebar,
        behaviour: defaultRightSidebarBehavior,
        mainContentVisible: true,
        softOpen: false,
        smallScreenBehaviour: SidebarBehaviour.AUTOHIDE,
        smallScreenStatus: SidebarStatus.CLOSED,
    },
    leftSidebar: {
        status: defaultLeftSidebar,
        behaviour: defaultLeftSidebarBehavior,
        mainContentVisible: true,
        softOpen: false,
        smallScreenBehaviour: SidebarBehaviour.AUTOHIDE,
        smallScreenStatus: SidebarStatus.CLOSED,
    },
    showSidebarOverlays: false,
    isSmallScreenLayout: false,
    isCartToggleVisible: false,
    softOpenSidebarLoaded: false,
    alternateCartContent: null,
};

export const UISlice = createSlice({
    name: 'userInterface',
    initialState,
    reducers: {
        setSoftOpenSidebarLoaded: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.softOpenSidebarLoaded = payload;
        },
        setRightSidebarStatus: (
            state,
            {payload}: PayloadAction<SidebarStatus>
        ) => {
            state.rightSidebar.status = payload;
        },
        setRightSidebarBehaviour: (
            state,
            {payload}: PayloadAction<SidebarBehaviour>
        ) => {
            state.rightSidebar.behaviour = payload;
        },
        setRightSidebarMainContentVisible: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.rightSidebar.mainContentVisible = payload;
        },
        setLeftSidebarStatus: (
            state,
            {payload}: PayloadAction<SidebarStatus>
        ) => {
            state.leftSidebar.status = payload;
        },
        setLeftSidebarBehaviour: (
            state,
            {payload}: PayloadAction<SidebarBehaviour>
        ) => {
            state.leftSidebar.behaviour = payload;
        },
        setLeftSidebarMainContentVisible: (
            state,
            {payload}: PayloadAction<boolean>
        ) => {
            state.leftSidebar.mainContentVisible = payload;
        },
        setIsSmallScreenLayout: (state, {payload}: PayloadAction<boolean>) => {
            state.isSmallScreenLayout = payload;
        },
        setShowSidebarOverlays: (state, {payload}: PayloadAction<boolean>) => {
            state.showSidebarOverlays = payload;
        },
        setIsCartToggleVisible: (state, {payload}: PayloadAction<boolean>) => {
            state.isCartToggleVisible = payload;
        },
        setAlternateCartContent: (
            state,
            {payload}: PayloadAction<ReactElement>
        ) => {
            state.alternateCartContent = payload;
        },
        setRightSidebarSoftOpen: (state, {payload}: PayloadAction<boolean>) => {
            state.rightSidebar.softOpen = payload;
        },
        setRightSmallScreenBehaviour: (
            state,
            {payload}: PayloadAction<SidebarBehaviour>
        ) => {
            state.rightSidebar.smallScreenBehaviour = payload;
        },
        setRightSmallScreenStatus: (
            state,
            {payload}: PayloadAction<SidebarStatus>
        ) => {
            state.rightSidebar.smallScreenStatus = payload;
        },
    },
});

export const getUI = (store: AppState) => store.UI;

export const {
    setRightSidebarStatus,
    setRightSidebarBehaviour,
    setRightSidebarMainContentVisible,
    setLeftSidebarStatus,
    setLeftSidebarBehaviour,
    setLeftSidebarMainContentVisible,
    setIsSmallScreenLayout,
    setShowSidebarOverlays,
    setIsCartToggleVisible,
    setAlternateCartContent,
    setRightSidebarSoftOpen,
    setRightSmallScreenBehaviour,
    setRightSmallScreenStatus,
    setSoftOpenSidebarLoaded,
} = UISlice.actions;

export default UISlice.reducer;
