import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {HingeStyle} from 'shared/types/HingeStyle';
import {CabinetTop} from 'shared/types/CabinetTop';
import {DrawerSystem} from 'shared/types/DrawerSystem';
import {AppState} from 'store/customer/storeSetup';
import {ProductSize} from 'shared/types/ProductSize';
import {GapSize} from 'shared/types/GapSize';

interface RoomState {
    hingeStyle?: HingeStyle;
    drawerStyle?: DrawerSystem;
    cabinetTop?: CabinetTop;
    productSize?: ProductSize;
    gapSize?: GapSize;
    isRoomLoading: boolean;
}

export type RoomData = Pick<
    RoomState,
    'hingeStyle' | 'drawerStyle' | 'cabinetTop' | 'productSize' | 'gapSize'
>;

const initialState: RoomState = {
    hingeStyle: null,
    drawerStyle: null,
    cabinetTop: null,
    productSize: null,
    gapSize: null,
    isRoomLoading: false,
};

const roomSlice = createSlice({
    name: 'roomError',
    initialState,
    reducers: {
        isRoomLoadingSet: (state, {payload}: PayloadAction<boolean>) => {
            state.isRoomLoading = payload;
        },
        productSizeSet: (state, {payload}: PayloadAction<ProductSize>) => {
            state.productSize = payload;
        },
        gapSizeSet: (state, {payload}: PayloadAction<GapSize>) => {
            state.gapSize = payload;
        },
        hingeStyleSet: (state, {payload}: PayloadAction<HingeStyle>) => {
            state.hingeStyle = payload;
        },
        drawerStyleSet: (state, {payload}: PayloadAction<DrawerSystem>) => {
            state.drawerStyle = payload;
        },
        cabinetTopSet: (state, {payload}: PayloadAction<CabinetTop>) => {
            state.cabinetTop = payload;
        },
        roomSet: (state, {payload}: PayloadAction<RoomData>) => {
            state.hingeStyle = payload.hingeStyle;
            state.drawerStyle = payload.drawerStyle;
            state.cabinetTop = payload.cabinetTop;
            state.productSize = payload.productSize;
            state.gapSize = payload.gapSize;
        },
    },
});

export const selectHingeStyle = (store: AppState) => store.room.hingeStyle;
export const selectDrawerStyle = (store: AppState) => store.room.drawerStyle;
export const selectCabinetTop = (store: AppState) => store.room.cabinetTop;
export const selectProductSize = (store: AppState) => store.room.productSize;
export const selectGapSize = (store: AppState) => store.room.gapSize;
export const selectIsRoomLoading = (store: AppState) =>
    store.room.isRoomLoading;

export const {
    hingeStyleSet,
    drawerStyleSet,
    cabinetTopSet,
    roomSet,
    productSizeSet,
    gapSizeSet,
    isRoomLoadingSet,
} = roomSlice.actions;
export default roomSlice.reducer;
