import {createSlice, PayloadAction, createSelector} from '@reduxjs/toolkit';
import {
    Product,
    ValidStructureInterface,
} from 'components/customer/Product/entity';
import {AppState} from 'store/customer/storeSetup';
import {cloneDeep} from 'lodash';
import {LegacyDoorInterface} from 'components/customer/Materials/helper/doorHelper';
import {LegacyEdgeInterface} from 'components/customer/Materials/helper/edgeHelper';
import {LegacyMaterialInterface} from 'components/customer/Materials/helper/materialHelper';
import {getNextRoomCabinetNumber} from 'components/customer/QFPRedux/helpers/getNextRoomCabinetNumber';
import {getUpdatedForbiddenNumbers} from 'components/customer/QFPRedux/helpers/getUpdatedForbiddenNumbers';

// TODO: note that some properties are in snake case.
// change these to camel case whtn old qfp is phased out.
export interface ValidationDataInterface {
    cabinet_ext_colour: {
        maxHeight: number;
        maxWidth: number;
        customColour: boolean;
        doubleSided: boolean;
        horGrain: boolean;
        hidden?: boolean;
    };
    hasHorizontalGrain: boolean;
    hasDoubleSided: boolean;
    hasCustomColour: boolean;
    isAdvanced: boolean;
    cabinet_door?: LegacyDoorInterface;
    cabinet_ext_edge_colour?: {
        hidden?: boolean;
    };
    drawer_amount?: number;
}

export interface QFPPreviewInterface {
    hasBorders: string | boolean;
    hasBottomBorders: boolean;
    hasEdging: boolean;
    preview: string;
    hasCutout: boolean;
    hasDoorType: boolean;
    isPanel: boolean;
    hasDoorHinge: boolean;
    hasDoorHang: boolean;
    extMaterial?: LegacyMaterialInterface;
    extEdge?: LegacyEdgeInterface;
}

export enum ProductStatus {
    IDLE,
    SAVING,
    PROCESSING,
}

export enum Status {
    IDLE,
    SAVING,
    DONE,
}

export interface QFPInterface {
    loading?: boolean;
    fetchingCost?: boolean;
    costUnit?: number;
    cost?: number;
    product?: Partial<Product>;
    index?: number;
    deletedIndex?: number;
    validationData?: ValidationDataInterface;
    previewOption?: QFPPreviewInterface;
    updated?: boolean;
    isValid?: boolean;
    persisted?: boolean;
    initialized?: boolean;
    deleted?: boolean;
    wasDeleted?: boolean;
    savingStatus: ProductStatus;
    error?: string;
    activeHorizontalMidRail?: number;
    activeVerticalMidRail?: number;
}

interface QFPStateInterface {
    triggerSave: boolean;
    activeIndex: number;
    validate: number[];
    qfps: Partial<QFPInterface>[];
    deleted: number[];
    totalProductsInJob: number;
    forbiddenIndexes: number[]; // NOTE: Basically roomCabinet numbers for normal products which should not be assigned to qfp products
    totalSaving: number;
    status: Status;
}

type Meta = {index?: number};

type StateUpdaterInterface<T> = {
    state: QFPStateInterface;
    qfp: Partial<QFPInterface>;
    payload: T;
    meta?: Meta;
};

type StateUpdater<T> = (stateUpdaterProps: StateUpdaterInterface<T>) => void;
type SelectorType<T> = (qfp: QFPInterface, index?: number) => T;

const genericReducer = <T>(updater: StateUpdater<T>) => ({
    reducer: (
        state: QFPStateInterface,
        {payload, meta}: PayloadAction<T, string, Meta>
    ) => {
        const currentIndex =
            typeof meta.index === 'undefined' ? state.activeIndex : meta.index;

        const qfp = state.qfps[Number(currentIndex)];
        updater({state, qfp, payload, meta});
    },
    prepare: (payload: T, index?: number) => {
        return {payload, meta: {index}};
    },
});

const qfpStateSlice = createSlice({
    name: 'qfp',
    initialState: {
        triggerSave: false,
        activeIndex: 0,
        totalProductsInJob: 0,
        qfps: [],
        deleted: [] as number[],
        forbiddenIndexes: [],
        totalSaving: 0,
        status: Status.IDLE,
    } as QFPStateInterface,
    reducers: {
        totalSavingSet: (state, {payload}: PayloadAction<number>) => {
            state.totalSaving = payload;
        },
        statusSet: (state, {payload}: PayloadAction<Status>) => {
            state.status = payload;
        },
        activeHorizontalMidRailSet: genericReducer<number>(({qfp, payload}) => {
            if (qfp) qfp.activeHorizontalMidRail = payload;
        }),
        activeVerticalMidRailSet: genericReducer<number>(({qfp, payload}) => {
            if (qfp) qfp.activeVerticalMidRail = payload;
        }),
        forbiddenIndexesSet: (state, {payload}: PayloadAction<number[]>) => {
            state.forbiddenIndexes = payload;
        },
        errorSet: genericReducer<string>(({qfp, payload}) => {
            if (payload == '') {
                const {error, ...rest} = qfp;
                qfp = rest;
            } else {
                qfp.error = payload;
                qfp.savingStatus = ProductStatus.IDLE;
            }
        }),
        clearErrors: (state) => {
            state.qfps.forEach((qfp) => {
                delete qfp.error;
            });
        },
        triggerSave: (state, {payload}: PayloadAction<boolean>) => {
            state.triggerSave = payload;
        },
        activeSet: (state, {payload}: PayloadAction<number>) => {
            state.activeIndex = payload;
        },
        totalProductsInJobSet: (state, {payload}: PayloadAction<number>) => {
            state.totalProductsInJob = payload;
        },
        qfpsSet: (state, {payload}: PayloadAction<Partial<QFPInterface>[]>) => {
            if (payload.length == 0) {
                state.totalProductsInJob = 0;
            }

            payload.forEach((qfp) => {
                if (!qfp.persisted) {
                    qfp.product.room_cab_number = state.totalProductsInJob + 1;

                    state.totalProductsInJob++;
                }
            });
            state.qfps = payload;
        },
        validateRemove: (state, {payload}: PayloadAction<number>) => {
            if (state.validate.includes(payload)) {
                state.validate = state.validate.filter(
                    (index) => index != payload
                );
            }
        },
        initialisedSet: genericReducer<boolean>(({qfp, payload}) => {
            if (qfp) qfp.initialized = payload;
        }),
        fetchingCostSet: genericReducer<boolean>(({qfp, payload}) => {
            if (qfp) qfp.fetchingCost = payload;
        }),
        validationDataSet: genericReducer<Partial<ValidationDataInterface>>(
            ({qfp, payload}) => {
                if (qfp)
                    qfp.validationData = {
                        ...qfp.validationData,
                        ...payload,
                    };
            }
        ),
        previewOptionSet: genericReducer<Partial<QFPPreviewInterface>>(
            ({qfp, payload}) => {
                if (qfp)
                    qfp.previewOption = {
                        ...qfp.previewOption,
                        ...payload,
                    };
            }
        ),
        costSet: genericReducer<number>(({qfp, payload: cost}) => {
            if (qfp) qfp.cost = cost;
        }),
        updateSet: genericReducer<boolean>(({qfp, payload}) => {
            if (qfp) {
                qfp.updated = payload;

                if (payload) {
                    qfp.persisted = false;
                }
            }
        }),
        productSet: genericReducer<{
            product: Partial<Product>;
            validStructure?: ValidStructureInterface;
        }>(({qfp, payload: {product}}) => {
            if (qfp) qfp.product = {...qfp.product, ...product};
        }),
        productUpdate: genericReducer<Partial<Product>>(
            ({qfp, payload: product}) => {
                if (qfp) {
                    qfp.product = {
                        ...{
                            ...qfp.product,
                        },
                        ...product,
                        initial: false,
                    };
                }
            }
        ),
        loadingSet: genericReducer<boolean>(({qfp, payload: loading}) => {
            if (qfp) qfp.loading = loading;
        }),
        validSet: genericReducer<boolean>(({qfp, payload}) => {
            if (qfp) qfp.isValid = payload;
        }),
        deleteQfp: genericReducer<number>(({state, qfp, payload: index}) => {
            if (qfp) {
                qfp.deleted = true;

                state.deleted.push(index);

                let roomCabinetNumber = 0;
                state.qfps = state.qfps.map((qfp, QFPindex) => {
                    if (index == QFPindex) {
                        qfp.deleted = true;
                        qfp.persisted = false;
                        state.forbiddenIndexes = getUpdatedForbiddenNumbers(
                            qfp.product.room_cab_number,
                            state.forbiddenIndexes
                        );
                    }

                    if (QFPindex > index && !qfp.deleted) {
                        qfp.product.room_cab_number = getNextRoomCabinetNumber(
                            roomCabinetNumber,
                            state.forbiddenIndexes
                        );
                    }

                    if (!qfp?.deleted) {
                        roomCabinetNumber = qfp.product.room_cab_number;
                    }
                    return qfp;
                });
                state.totalProductsInJob -= 1;
            }
        }),
        undoDeleteQfp: (state) => {
            if (state.deleted.length > 0) {
                const index = state.deleted.pop();

                const undoQfpArray = state.qfps.slice(index, index + 1);
                if (undoQfpArray.length > 0) {
                    const deletedQfp = undoQfpArray[0];
                    const undoQfp = cloneDeep(deletedQfp);
                    undoQfp.deleted = false;
                    undoQfp.wasDeleted = true;
                    undoQfp.updated = true;
                    undoQfp.product.room_cab_number =
                        state.totalProductsInJob + 1;

                    state.qfps.push(undoQfp);
                    state.totalProductsInJob += 1;
                    state.activeIndex = state.qfps.length - 1;
                }
            }
        },
        addQfp: (state) => {
            let qfp;
            if (state.qfps.length >= 1) {
                const lastIndex = state.qfps.length - 1;
                qfp = cloneDeep(state.qfps[Number(lastIndex)]);
            }

            qfp.persisted = false;
            qfp.updated = false;
            qfp.product.id = undefined;
            qfp.product.copy = true;
            qfp.product.job_cabinet_id = undefined;
            qfp.cost = 0;
            qfp.savingStatus = ProductStatus.IDLE; // Status to IDLE

            state.qfps.push({
                ...qfp,
                product: {
                    ...qfp.product,
                    id: undefined,
                    job_cabinet_id: undefined,
                    cabinet_quantity: 1,
                    width: '',
                    height: '',
                    cabinet_panel_width: '',
                    cabinet_panel_length: '',
                    cabinet_width_door_1: '',
                    cabinet_note: '',
                    cabinet_comment: '',
                    room_cab_number: state.totalProductsInJob + 1,
                },
                loading: false,
                initialized: false,
                deleted: false,
            });
            state.activeIndex = state.qfps.length - 1;
            state.totalProductsInJob += 1;
        },
        copyQfp: genericReducer<number>(({state, qfp, payload: quantity}) => {
            if (qfp) {
                const qfpCopy = cloneDeep(qfp);
                qfpCopy.loading = false;
                qfpCopy.savingStatus = ProductStatus.IDLE;
                qfpCopy.persisted = false;
                qfpCopy.updated = true;

                qfpCopy.product.id = undefined;
                qfpCopy.product.job_cabinet_id = undefined;
                qfpCopy.product.copy = true;

                const newQfps = Array.from({length: quantity}, (_, index) => {
                    const qfp = {
                        ...cloneDeep(qfpCopy),
                        initialized: false,
                        deleted: false,
                    };

                    qfp.product.room_cab_number =
                        state.totalProductsInJob + (index + 1);

                    return qfp;
                });

                state.qfps = [...state.qfps, ...newQfps];
                state.activeIndex = state.qfps.length - quantity;
                state.totalProductsInJob += quantity;
            }
        }),
        savingSet: genericReducer<ProductStatus>(({qfp, payload}) => {
            qfp.savingStatus = payload;
        }),
        persistedSet: genericReducer<boolean>(({qfp, payload}) => {
            qfp.persisted = payload;
        }),
        clearAll: (state) => {
            state.activeIndex = 0;
            state.totalProductsInJob = 0;
            state.qfps = [];
            state.deleted = [];
            state.triggerSave = false;
        },
        jobIdSet: genericReducer<{id: number; roomCabNumber: number}>(
            ({qfp, payload}) => {
                if (qfp) {
                    qfp.product.job_cabinet_id = payload.id;
                    qfp.persisted = true;
                    qfp.updated = false;
                    qfp.wasDeleted = false;
                    qfp.savingStatus = ProductStatus.IDLE;
                }
            }
        ),
        clearDeleted: (state) => {
            if (state.deleted.length > 0) {
                state.deleted = [];

                if (state.qfps.length > 0) {
                    const permanentlyDeleted = state.qfps.map((qfp) => ({
                        ...qfp,
                        persisted: qfp.deleted ? true : qfp.persisted,
                    }));

                    state.qfps = permanentlyDeleted;
                }
            }
        },
    },
});

const selectValidate = (state: AppState) => state.qfp.validate;
export const hasDeleted = (state: AppState) => state.qfp.deleted.length > 0;
export const selectQFPCount = (state: AppState) => state.qfp.qfps.length;
export const selectQFPs = (state: AppState, index?: number) => state.qfp.qfps;
const selectIndex = (state: AppState, index: number) => index;
export const selectLoadingQfps = (state: AppState) =>
    state.qfp.qfps.map((qfp) => qfp.loading).some(Boolean);
export const selectTriggerSave = (state: AppState) => state.qfp.triggerSave;
export const selectTotalSaving = (state: AppState) => state.qfp.totalSaving;
export const selectStatus = (state: AppState) => state.qfp.status;

const genericSelectorArray = [selectQFPs, selectIndex];
const genericSelector = <T>(selector: SelectorType<T>) => {
    return (qfps: QFPInterface[], index: number) => {
        const qfp = qfps[Number(index)];

        if (qfp) {
            return selector(qfp, index);
        }
    };
};
export const selectCost = createSelector(
    genericSelectorArray,
    genericSelector<number>((qfp) => qfp && qfp.cost)
);
export const selectWasDeleted = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.wasDeleted)
);
// saving to : check it
export const selectSaving = createSelector(
    genericSelectorArray,
    genericSelector<boolean>(
        (qfp) => qfp && qfp.savingStatus == ProductStatus.PROCESSING
    )
);
export const selectProduct = createSelector(
    genericSelectorArray,
    genericSelector<Partial<Product>>((qfp) => qfp && qfp.product)
);
export const selectPreviewOptions = createSelector(
    genericSelectorArray,
    genericSelector<QFPPreviewInterface>((qfp) => qfp && qfp.previewOption)
);
export const selectValidationData = createSelector(
    genericSelectorArray,
    genericSelector<ValidationDataInterface>((qfp) => qfp && qfp.validationData)
);
export const selectLoading = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.loading)
);
export const selectUpdated = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.updated)
);
export const selectIsValid = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.isValid)
);
export const selectPersisted = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.persisted)
);
export const selectFetchingCost = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.fetchingCost)
);
export const selectDeleted = createSelector(
    genericSelectorArray,
    genericSelector<boolean>((qfp) => qfp && qfp.deleted)
);
export const selectActiveHorizontalMidRail = createSelector(
    genericSelectorArray,
    genericSelector<number>((qfp) => qfp && qfp.activeHorizontalMidRail)
);
export const selectActiveVerticalMidRail = createSelector(
    genericSelectorArray,
    genericSelector<number>((qfp) => qfp && qfp.activeVerticalMidRail)
);
export const selectActive = createSelector(
    [
        (state: AppState) => state.qfp,
        (state: AppState, index?: number) => index,
    ],
    (qfp, index) => {
        return qfp.activeIndex == index;
    }
);
export const selectShouldValidate = createSelector(
    [selectValidate, selectIndex],
    (validate, index) => {
        if (validate && validate.includes(index)) {
            return true;
        }

        return false;
    }
);
export const selectQueueProducts = createSelector([selectQFPs], (qfps) =>
    qfps
        .map((qfp, index) => ({...qfp, index}))
        .filter((qfp) => qfp.savingStatus == ProductStatus.SAVING)
);
export const selectIdle = createSelector(
    [selectQFPs, selectIndex],
    (qfps, index) => {
        const qfp = qfps[Number(index)];

        if (qfp) {
            return (
                qfp.savingStatus == ProductStatus.IDLE &&
                (typeof qfp.error == 'undefined' || qfp.error == '')
            );
        }

        return false;
    }
);

export const {
    qfpsSet,
    addQfp,
    activeSet,
    productSet,
    copyQfp,
    deleteQfp,
    undoDeleteQfp,
    costSet,
    productUpdate,
    clearAll,
    validationDataSet,
    previewOptionSet,
    loadingSet,
    validSet,
    fetchingCostSet,
    updateSet,
    validateRemove,
    initialisedSet,
    totalProductsInJobSet,
    jobIdSet,
    clearDeleted,
    savingSet,
    triggerSave,
    errorSet,
    forbiddenIndexesSet,
    activeHorizontalMidRailSet,
    activeVerticalMidRailSet,
    totalSavingSet,
    statusSet,
    clearErrors,
    persistedSet,
} = qfpStateSlice.actions;

export default qfpStateSlice.reducer;
