import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {AppState} from 'store/customer/storeSetup';

export interface EdgeFinishLock {
    index: number;
    locked: boolean;
}
export interface InitialLoad {
    index: number;
    loaded: boolean;
}

export interface SelectedEdge {
    fieldName: string;
    edgeFinishId: number | string;
}

interface EdgeRestriction {
    edgeFinishId: string | number;
    hasOtherEdges: boolean;
    restrictedToOne: boolean;
}

interface EdgeFinishState {
    edgeFinishLock: EdgeFinishLock[];
    initialLoad: InitialLoad[];
    selectedEdges: {[key: number]: {[key: string]: EdgeRestriction}};
}

const EdgeFinishSlice = createSlice({
    name: 'edgeFinish',
    initialState: {
        edgeFinishLock: [],
        initialLoad: [],
        selectedEdges: {},
    } as EdgeFinishState,
    reducers: {
        edgeLockSet: (state, {payload}: PayloadAction<EdgeFinishLock>) => {
            const index = state.edgeFinishLock.findIndex(
                (value) => value.index == payload.index
            );

            if (index > -1) state.edgeFinishLock.splice(index, 1);

            state.edgeFinishLock.push(payload);
        },
        edgeFinishAppend: (state) => {
            if (state.edgeFinishLock.length > 0) {
                const index = state.edgeFinishLock.length - 1;

                state.edgeFinishLock.push({
                    locked: state.edgeFinishLock[Number(index)].locked,
                    index: index + 1,
                });
            }

            if (state.initialLoad.length > 0) {
                const index = state.initialLoad.length - 1;

                state.initialLoad.push({
                    loaded: state.initialLoad[Number(index)].loaded,
                    index: index + 1,
                });
            }
        },
        edgeFinishCopy: {
            reducer: (
                state,
                {payload, meta: index}: PayloadAction<number, string, number>
            ) => {
                const edgeFinishLock = state.edgeFinishLock[Number(index)];

                if (edgeFinishLock) {
                    const initialLoads: InitialLoad[] = [];
                    const newEdgeFinishes = Array.from(
                        {length: payload},
                        (_, index) => {
                            initialLoads.push({
                                loaded: true,
                                index: state.edgeFinishLock.length + index,
                            });

                            return {
                                locked: edgeFinishLock.locked,
                                index: state.edgeFinishLock.length + index,
                            };
                        }
                    );

                    state.edgeFinishLock = [
                        ...state.edgeFinishLock,
                        ...newEdgeFinishes,
                    ];
                    state.initialLoad = [...state.initialLoad, ...initialLoads];
                }
            },
            prepare: (payload: number, index: number) => ({
                payload,
                meta: index,
            }),
        },
        clearAllEdgeFinishData: (state) => {
            state.edgeFinishLock = [];
            state.initialLoad = [];
            state.selectedEdges = {};
        },
        clearSelectedEdgesByIndex: (
            state,
            {payload}: PayloadAction<number>
        ) => {
            const index = payload;
            state.selectedEdges[Number(index)] = {};
        },
        selectEdgeFinish: (
            state,
            {
                payload,
            }: PayloadAction<{
                fieldName: string;
                edgeFinishId: string | number;
                hasOtherEdges: boolean;
                restrictedToOne: boolean;
                index: number;
            }>
        ) => {
            const {
                index,
                fieldName,
                hasOtherEdges,
                restrictedToOne,
                edgeFinishId,
            } = payload;

            if (!state.selectedEdges[Number(index)]) {
                state.selectedEdges[Number(index)] = {};
            }

            if (!state.selectedEdges[Number(index)][String(fieldName)]) {
                state.selectedEdges[Number(index)][String(fieldName)] = {
                    edgeFinishId: null,
                    hasOtherEdges: false,
                    restrictedToOne: false,
                };
            }

            state.selectedEdges[Number(index)][String(fieldName)].edgeFinishId =
                edgeFinishId;
            state.selectedEdges[Number(index)][
                String(fieldName)
            ].hasOtherEdges = hasOtherEdges;
            state.selectedEdges[Number(index)][
                String(fieldName)
            ].restrictedToOne = restrictedToOne;
        },
    },
});

export const edgeFinishLockByIndex = createSelector(
    [
        (store: AppState) => store.edgeFinish.edgeFinishLock,
        (state, index: number) => index,
    ],
    (edgeFinishLocks, index) => {
        return edgeFinishLocks.find(
            (edgeFinishLock) => edgeFinishLock.index == index
        );
    }
);

export const initialLoadByIndex = createSelector(
    [
        (store: AppState) => store.edgeFinish.initialLoad,
        (state, index: number) => index,
    ],
    (initialLoads, index) => {
        return initialLoads.find((initialLoad) => initialLoad.index == index);
    }
);

export const selectedEdgesByIndex = createSelector(
    [
        (store: AppState) => store.edgeFinish.selectedEdges,
        (state, index: number) => index,
    ],
    (edgeFinishState, index) => {
        return edgeFinishState[Number(index)] || {};
    }
);

export const {
    edgeLockSet,
    edgeFinishAppend,
    edgeFinishCopy,
    clearAllEdgeFinishData,
    clearSelectedEdgesByIndex,
    selectEdgeFinish,
} = EdgeFinishSlice.actions;
export default EdgeFinishSlice.reducer;
