import {doorSet} from 'components/customer/Materials/store/materialSlice';
import {AppDispatch, AppState} from 'store/customer/storeSetup';
import {ListenerEffectAPI, PayloadAction} from '@reduxjs/toolkit';
import {Door} from 'components/customer/Materials/entity';
import {Material} from 'components/customer/Materials/entity';
import {cloneDeep} from 'lodash';

export const doorSetMiddleware = (
    setMaterial: (
        material: Material,
        updateValue?: boolean,
        updateValueOnly?: boolean
    ) => void,
    index?: number
) => ({
    actionCreator: doorSet,
    effect: (
        action: PayloadAction<Door, string, {index?: number}>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        if (action.meta && action.meta.hasOwnProperty('index')) {
            if (action.meta.index != index) {
                return;
            }
        }

        const state = listenerApi.getState();
        const materials = state.materials.materials[Number(index)];
        const doorFilters = materials.material.door_filters;
        const door = action.payload;

        if (Array.isArray(doorFilters) && door && doorFilters.length > 1) {
            const material = cloneDeep(
                doorFilters.find(
                    (material) => material.door_filter == door.filter_name
                )
            );

            if (material) {
                material.door_filters = doorFilters;
                setMaterial(material, true, false);
            }
        }
    },
});
