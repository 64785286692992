import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import * as THREE from 'three';
import {PreviewLocation, Dimension} from 'Preview3D/types';

interface Preview3D {
    data: object | null;
    template: string;
    exteriorTexture: THREE.Texture;
    exteriorEdgeTexture: THREE.Texture;
    carcaseTexture: THREE.Texture;
    carcaseEdgeTexture: THREE.Texture;
    baseInnerDrawerFaceHeight: number;
    isPreviewHighlighted: boolean;
    previewHighlightUpdateFreeze: boolean;
    texturePaths: {
        exterior: string;
        carcase: string;
        exteriorEdge: string;
        carcaseEdge: string;
    };
    shelfSetBack: number;
    showExpandedView: Record<PreviewLocation, boolean>;
    expandedViewCameraPosition: Dimension & {
        zoom: number;
    };
    hasWebGLError: boolean;
}

const viewerSlice = createSlice({
    name: 'preview_3d',
    initialState: {
        data: null,
        template: '',
        exteriorTexture: null,
        exteriorEdgeTexture: null,
        carcaseTexture: null,
        carcaseEdgeTexture: null,
        baseInnerDrawerFaceHeight: 0,
        isPreviewHighlighted: true,
        previewHighlightUpdateFreeze: false,
        texturePaths: {
            exterior: '',
            carcase: '',
            exteriorEdge: '',
            carcaseEdge: '',
        },
        shelfSetBack: 0,
        showExpandedView: {
            DOORS: false,
            DRAWERS: false,
            SHELVES: false,
            SPECS: false,
        },
        expandedViewCameraPosition: {
            x: 0,
            y: 0,
            z: 0,
            zoom: 0,
        },
        hasWebGLError: false,
    } as Preview3D,
    reducers: {
        dataSet: (state, action: PayloadAction<object | null>) => {
            state.data = action.payload;
        },
        templateSet: (state, action: PayloadAction<string>) => {
            state.template = action.payload;
        },
        clearPreview: (state) => {
            state.template = '';
            state.data = null;
        },
        setExteriorTexture: (state, action: PayloadAction<THREE.Texture>) => {
            state.exteriorTexture = action.payload;
        },
        setExteriorEdgeTexture: (
            state,
            action: PayloadAction<THREE.Texture>
        ) => {
            state.exteriorEdgeTexture = action.payload;
        },
        setCarcaseTexture: (state, action: PayloadAction<THREE.Texture>) => {
            state.carcaseTexture = action.payload;
        },
        setCarcaseEdgeTexture: (
            state,
            action: PayloadAction<THREE.Texture>
        ) => {
            state.carcaseEdgeTexture = action.payload;
        },
        setBaseInnerDrawerFaceHeight: (
            state,
            action: PayloadAction<string[]>
        ) => {
            if (action.payload?.length === 1) {
                const regex = /(\d+)x(\d+)/;
                const match = action.payload[0].match(regex);

                if (match && match[1]) {
                    state.baseInnerDrawerFaceHeight = Number(match[1]);
                } else {
                    if (action.payload[0].indexOf('Deep') > -1)
                        state.baseInnerDrawerFaceHeight = 200;
                    if (action.payload[0].indexOf('Shallow') > -1)
                        state.baseInnerDrawerFaceHeight = 120;
                }
            } else {
                state.baseInnerDrawerFaceHeight = 0;
            }
        },
        setIsPreviewHighlighted: (state, action: PayloadAction<boolean>) => {
            if (!action.payload && state.previewHighlightUpdateFreeze) return;

            state.isPreviewHighlighted = action.payload;
        },
        setPreviewHighlightUpdateFreeze: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.previewHighlightUpdateFreeze = action.payload;
        },
        reset: (state) => {
            state.isPreviewHighlighted = true;
        },
        setTexturePath: (
            state,
            action: PayloadAction<{
                type: 'EXTERIOR' | 'CARCASE' | 'EXTERIOR_EDGE' | 'CARCASE_EDGE';
                path: string;
            }>
        ) => {
            switch (action.payload.type) {
                case 'CARCASE':
                    state.texturePaths.carcase = action.payload.path;
                    break;
                case 'CARCASE_EDGE':
                    state.texturePaths.carcaseEdge = action.payload.path;
                    break;
                case 'EXTERIOR_EDGE':
                    state.texturePaths.exteriorEdge = action.payload.path;
                    break;
                default:
                    state.texturePaths.exterior = action.payload.path;
                    break;
            }
        },
        setShelfSetBack: (state, action: PayloadAction<number>) => {
            state.shelfSetBack = action.payload;
        },
        setShowExpandedView: (
            state,
            action: PayloadAction<{type: PreviewLocation; value: boolean}>
        ) => {
            switch (action.payload.type) {
                case 'DOORS':
                    state.showExpandedView.DOORS = action.payload.value;
                    break;
                case 'DRAWERS':
                    state.showExpandedView.DRAWERS = action.payload.value;
                    break;
                case 'SHELVES':
                    state.showExpandedView.SHELVES = action.payload.value;
                    break;
                default:
                    state.showExpandedView.SPECS = action.payload.value;
                    break;
            }
        },
        setExpandedViewCameraPosition: (
            state,
            action: PayloadAction<Dimension & {zoom: number}>
        ) => {
            state.expandedViewCameraPosition = action.payload;
        },
        setHasWebGLError: (state, action: PayloadAction<boolean>) => {
            state.hasWebGLError = action.payload;
        },
    },
});

export const selectData = (state: AppState) => state.preview3D.data;
export const selectTemplate = (state: AppState) => state.preview3D.template;
export const getTextures = (state: AppState) => ({
    exteriorTexture: state.preview3D.exteriorTexture,
    exteriorEdgeTexture: state.preview3D.exteriorEdgeTexture,
    carcaseTexture: state.preview3D.carcaseTexture,
    carcaseEdgeTexture: state.preview3D.carcaseEdgeTexture,
});
export const getBaseInnerDrawerFaceHeight = (state: AppState) =>
    state.preview3D.baseInnerDrawerFaceHeight;
export const getIsPreviewHighlighted = (state: AppState) =>
    state.preview3D.isPreviewHighlighted;
export const getTexturePaths = (state: AppState) =>
    state.preview3D.texturePaths;

export const getShowExpandedView = (state: AppState) =>
    state.preview3D.showExpandedView;

export const getExpandedViewCameraPosition = (state: AppState) =>
    state.preview3D.expandedViewCameraPosition;
export const getHasWebGLError = (state: AppState) =>
    state.preview3D.hasWebGLError;

export const {
    dataSet,
    templateSet,
    clearPreview,
    setExteriorTexture,
    setExteriorEdgeTexture,
    setCarcaseTexture,
    setCarcaseEdgeTexture,
    setBaseInnerDrawerFaceHeight,
    setIsPreviewHighlighted,
    setPreviewHighlightUpdateFreeze,
    reset,
    setTexturePath,
    setShelfSetBack,
    setShowExpandedView,
    setExpandedViewCameraPosition,
    setHasWebGLError,
} = viewerSlice.actions;

export default viewerSlice.reducer;
