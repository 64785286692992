import {AppApi} from 'store/customer/api';
const JobSundryItems = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobSundryItems: builder.query<
            void,
            {
                data: {
                    job_id: number;
                };
            }
        >({
            query: ({data}) => ({
                url: `/sundries/jobitems`,
                method: 'POST',
                body: {
                    ...data,
                },
            }),
            providesTags: ['JobSundryItems'],
        }),
        getJobSundryAttributes: builder.query<
            void,
            {
                id: number;
            }
        >({
            query: ({id}) => ({
                url: `/sundries/job_sundry_attributes`,
                method: 'POST',
                body: {
                    id: id,
                },
            }),
            providesTags: ['JobSundryAttributes'],
        }),
    }),
});

export const invalidateJobSundryItems = () =>
    JobSundryItems.util.invalidateTags(['JobSundryItems']);

export const invalidateJobSundryAttributes = () =>
    JobSundryItems.util.invalidateTags(['JobSundryAttributes']);

export const {useGetJobSundryItemsQuery, useGetJobSundryAttributesQuery} =
    JobSundryItems;
