import {
    createListenerMiddleware,
    addListener,
    TypedAddListener,
} from '@reduxjs/toolkit';
import {AppDispatch, AppState} from 'store/customer/storeSetup';

export const applicationListenerMiddleware = createListenerMiddleware();

export const addAppListener = addListener as TypedAddListener<
    AppState,
    AppDispatch
>;
