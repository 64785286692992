import notificationReducer from 'components/manufacturer/shared/NotificationSlice';

import {combineReducers} from '@reduxjs/toolkit';

import {apiReducerPath, apiReducer} from 'store/dashboard/appApi';

const rootReducer = combineReducers({
    notification: notificationReducer,
    [apiReducerPath]: apiReducer,
});

export default rootReducer;
