import {Door} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import {
    doorSet,
    pendingDoorSet,
} from 'components/customer/Materials/store/materialSlice';
import {Middleware} from 'redux';
import {AppState} from 'store/customer/storeSetup';
import {PayloadAction} from '@reduxjs/toolkit';

export const doorStyleMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<Door, string, {index?: number}>) => {
        const page = store.getState().application.page;
        if (action.type === doorSet.type && page == Page.ROOM) {
            let index = 0;
            if (
                action.meta &&
                action.meta.hasOwnProperty('index') &&
                typeof action.meta.index !== 'undefined'
            ) {
                index = action.meta.index;
            }

            const materials =
                store.getState().materials.materials[Number(index)];

            if (materials) {
                const currentValue = materials.door;
                const pendingDoor = materials.pendingDoor;
                const nextDoor = action.payload;

                // Checking if both the doors (current and one being selected) are
                // advanced. If borders value are same, then we don't need to
                // show the message saying borders will be updated. I guess.
                if (
                    (pendingDoor == null ||
                        typeof pendingDoor === 'undefined') &&
                    currentValue &&
                    currentValue.id != nextDoor.id &&
                    currentValue.is_advanced &&
                    nextDoor.is_advanced &&
                    (nextDoor.default_border_width_bottom != null ||
                        nextDoor.default_border_width_left != null ||
                        nextDoor.default_border_width_right != null ||
                        nextDoor.default_border_width_top != null ||
                        nextDoor.default_rails_horizontal_height != null ||
                        nextDoor.default_rails_vertical_width != null) &&
                    (nextDoor.default_border_width_bottom !=
                        currentValue.default_border_width_bottom ||
                        nextDoor.default_border_width_left !=
                            currentValue.default_border_width_left ||
                        nextDoor.default_border_width_right !=
                            currentValue.default_border_width_right ||
                        nextDoor.default_border_width_top !=
                            currentValue.default_border_width_top ||
                        nextDoor.default_rails_horizontal_height !=
                            currentValue.default_rails_horizontal_height ||
                        nextDoor.default_rails_vertical_width !=
                            currentValue.default_rails_vertical_width)
                ) {
                    return next(pendingDoorSet(nextDoor));
                }
            }
        }

        return next(action);
    };
