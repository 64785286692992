import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    forgotPassword,
    logout,
    resetPassword,
    selectUserAccount,
} from 'service';
import {AppState} from 'store/customer/storeSetup';

export const SESSION_CHANGE_MESSAGE =
    'Your details have been changed and this requires you to log in again.';

export enum Session {
    LOGGED_IN,
    LOGGED_OUT,
}

export interface UserAccount {
    id?: number;
    user_type: number;
    logo?: string;
    name: string;
    email?: string;
    domain: string;
    passwordReset?: boolean;
    redirectTo: string;
}

export interface Auth {
    session: Session;
    message: string;
    redirectTo: string;
    logoutPopup: boolean;
    redirecting: boolean;
}

export interface LoginActionInterface {
    username: string;
    password: string;
    keepLoggedIn: boolean;
}

export interface LoginActionResponse {
    accounts: UserAccount[];
    keepLoggedIn: boolean;
}

export interface PasswordResetActionInterface {
    id: number;
    token: string;
    password: string;
    confirmPassword: string;
}

interface selectUserAccountInterface {
    id: number;
    type: number;
    rememberMe: boolean;
}

const initialState: Auth = {
    session: Session.LOGGED_OUT,
    logoutPopup: false,
    message: null,
    redirectTo: null,
    redirecting: false,
};

export const logoutAction = createAsyncThunk<void, void, {rejectValue: string}>(
    'auth/logoutAction',
    async (_, {rejectWithValue}) => {
        try {
            await logout();

            indexedDB.deleteDatabase('CBCClientDatabase');
        } catch (e) {
            // console.log(e);
            return rejectWithValue('Could not logout');
        }
    }
);

export const selectUserAccountAction = createAsyncThunk<
    number,
    selectUserAccountInterface,
    {rejectValue: string}
>(
    'auth/selectUserAccountAction',
    async ({id, type, rememberMe}, {rejectWithValue}) => {
        try {
            await selectUserAccount(id, type, rememberMe);
        } catch (e) {
            // console.log(e);
            return rejectWithValue('Account could not be selected.');
        }
    }
);

export const passwordResetRequestAction = createAsyncThunk<
    string,
    string,
    {rejectValue: string}
>('auth/passwordResetRequestAction', async (email, {rejectWithValue}) => {
    try {
        return await forgotPassword(email);
    } catch (e) {
        if (e instanceof Error) {
            return rejectWithValue(e.message);
        }
    }
});

export const passwordResetAction = createAsyncThunk<
    string,
    PasswordResetActionInterface,
    {rejectValue: string}
>(
    'auth/passwordResetAction',
    async ({id, token, password, confirmPassword}, {rejectWithValue}) => {
        try {
            await resetPassword(id, token, password, confirmPassword);

            return 'Your new password was successfully saved. Please login using new password.';
        } catch (e) {
            if (e instanceof Error) {
                return rejectWithValue(e.message);
            }
        }
    }
);

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        sessionSet: (state, {payload}: PayloadAction<Session>) => {
            state.session = payload;
        },
        messageSet: (state, {payload}: PayloadAction<string>) => {
            state.message = payload;
        },
        redirectToSet: (state, {payload}: PayloadAction<string>) => {
            state.redirectTo = payload;
        },
        logoutPopupSet: (state, {payload}: PayloadAction<boolean>) => {
            state.logoutPopup = payload;
        },
        redirectingSet: (state, {payload}: PayloadAction<boolean>) => {
            state.redirecting = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutAction.fulfilled, (state) => {
            state.session = Session.LOGGED_OUT;
        });

        builder.addCase(
            passwordResetAction.fulfilled,
            (state, {payload}: PayloadAction<string>) => {
                state.message = payload;
            }
        );
    },
});

export const selectSession = (store: AppState) => store.auth.session;
export const selectMessage = (store: AppState) => store.auth.message;
export const selectRedirectTo = (store: AppState) => store.auth.redirectTo;
export const selectLogoutPopup = (store: AppState) => store.auth.logoutPopup;
export const selectRedirecting = (store: AppState) => store.auth.redirecting;

export const {
    sessionSet,
    messageSet,
    logoutPopupSet,
    redirectToSet,
    redirectingSet,
} = AuthSlice.actions;

export default AuthSlice.reducer;
