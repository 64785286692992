import {ListenerEffectAPI, Middleware, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppState} from 'store/customer/storeSetup';
import {
    activeSet,
    addQfp,
    copyQfp,
    deleteQfp,
    undoDeleteQfp,
    clearAll,
    previewOptionSet,
    validationDataSet,
    productSet,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {
    activeSet as materialActiveSet,
    deleteMaterials,
    undoDeleteMaterials,
    materialsAppend,
    materialsCopy,
    allDataCleared,
    materialSet,
    doorSet,
    edgeMaterialSet,
} from 'components/customer/Materials/store/materialSlice';
import {getProductPreview} from 'components/customer/QFPRedux/helpers/getProductPreview';
import {
    Door,
    Material,
    MaterialEdge,
} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import {
    Product,
    ValidStructureInterface,
} from 'components/customer/Product/entity';
import {
    edgeFinishAppend,
    edgeFinishCopy,
} from 'components/customer/EdgeFinishes/store/edgeFinishSlice';
import {mapDoorBack} from 'components/customer/Materials/helper/doorHelper';

// Deletes the corresponding material from state
export const qfpDeleteMiddleware = () => ({
    actionCreator: deleteQfp,
    effect: (
        action: PayloadAction<number>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        listenerApi.dispatch(deleteMaterials(action.payload, action.payload));
    },
});

const qfpProductSetMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (
        action: PayloadAction<
            {product: Product; validStructure: ValidStructureInterface},
            string,
            {index?: number}
        >
    ) => {
        if (action.type == productSet.type) {
            if (action.payload && action.payload.validStructure) {
                const index = action.meta.index;
                const validStructure = action.payload.validStructure;
                const productPreview = getProductPreview(validStructure);

                store.dispatch(previewOptionSet(productPreview, index));
                store.dispatch(
                    validationDataSet(
                        {
                            hasHorizontalGrain:
                                validStructure.hasHorizontalGrain,
                            hasDoubleSided: validStructure.hasDoubleSided,
                            hasCustomColour: validStructure.hasCustomColour,
                            drawer_amount: validStructure.drawer_amount,
                        },
                        index
                    )
                );
            }
        }

        return next(action);
    };

const qfpDoorSetMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<Door, string, {index?: number}>) => {
        if (action.type == doorSet.type) {
            const state = store.getState();
            const page = state.application.page;

            if (page == Page.QFP) {
                const index = action.meta.index;

                store.dispatch(
                    validationDataSet(
                        {
                            cabinet_door: mapDoorBack(action.payload),
                            isAdvanced: action.payload.is_advanced,
                        },
                        index
                    )
                );
            }
        }

        return next(action);
    };

const qfpMaterialSetMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<Material, string, {index?: number}>) => {
        if (action.type == materialSet.type) {
            const state = store.getState();
            const page = state.application.page;

            if (page == Page.QFP) {
                const index = action.meta.index;

                store.dispatch(
                    validationDataSet(
                        {
                            cabinet_ext_colour: {
                                maxHeight: action.payload.length,
                                maxWidth: action.payload.width,
                                customColour: action.payload.is_custom_colour,
                                doubleSided: action.payload.is_double_sided,
                                horGrain: action.payload.is_grained,
                                hidden: action.payload.is_hidden
                                    ? action.payload.is_hidden
                                    : false,
                            },
                        },
                        index
                    )
                );
            }
        }

        return next(action);
    };

const qfpEdgeMaterialSetMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<MaterialEdge, string, {index?: number}>) => {
        if (action.type == edgeMaterialSet.type) {
            const state = store.getState();
            const page = state.application.page;

            if (page == Page.QFP) {
                const index = action.meta.index;

                store.dispatch(
                    validationDataSet(
                        {
                            cabinet_ext_edge_colour: {
                                hidden: action.payload.is_hidden
                                    ? action.payload.is_hidden
                                    : false,
                            },
                        },
                        index
                    )
                );
            }
        }

        return next(action);
    };

// Creates the corresponding material active in state
const selectActiveMiddleware: Middleware<unknown, AppState> =
    (store) => (next) => (action: PayloadAction<number>) => {
        if (action.type == activeSet.type) {
            const index = action.payload;
            const activeIndex = store.getState().materials.activeIndex;

            if (index != activeIndex) store.dispatch(materialActiveSet(index));
        }

        return next(action);
    };

const addQfpMiddleware: Middleware<unknown, AppState> =
    (store) => (next) => (action: PayloadAction) => {
        if (action.type == addQfp.type) {
            store.dispatch(materialsAppend());
            store.dispatch(edgeFinishAppend());
        }

        return next(action);
    };

const copyQfpMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<number, string, {index?: number}>) => {
        if (action.type == copyQfp.type) {
            const index = action.meta.index;
            const quantity = action.payload;
            store.dispatch(materialsCopy(quantity, index));
            store.dispatch(edgeFinishCopy(quantity, index));
        }

        return next(action);
    };

// Undoes material delete when qfp delete undo is dispatched
const qfpUndoMiddleware: Middleware<unknown, AppState> =
    (store) => (next) => (action: PayloadAction) => {
        if (action.type == undoDeleteQfp.type) {
            store.dispatch(undoDeleteMaterials());
        }

        return next(action);
    };

const qfpActiveSetMiddleware: Middleware<unknown, AppState> =
    (store) => (next) => (action: PayloadAction<number>) => {
        if (action.type == activeSet.type) {
            const activeIndex = store.getState().qfp.activeIndex;

            if (activeIndex == action.payload) {
                return;
            }
        }

        return next(action);
    };

const qfpClearMiddleware: Middleware<unknown, AppState> =
    (store) => (next) => (action: PayloadAction) => {
        if (action.type == clearAll.type) {
            store.dispatch(allDataCleared());
        }

        return next(action);
    };

export const qfpMiddlewares = [
    selectActiveMiddleware,
    addQfpMiddleware,
    qfpUndoMiddleware,
    copyQfpMiddleware,
    qfpActiveSetMiddleware,
    qfpClearMiddleware,
    qfpProductSetMiddleware,
    qfpMaterialSetMiddleware,
    qfpDoorSetMiddleware,
    qfpEdgeMaterialSetMiddleware,
];
