import {Middleware, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {
    coordinatesSet,
    cornersSet,
    dimensionSet,
    joinsSet,
    pathsSet,
    typeSet,
} from 'components/customer/BTM/store/btmSlice';
import {getCoordinates} from 'components/customer/BTM/helper/getCoordinates';
import {cutoffs} from 'components/customer/BTM/helper/corner';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {getPathsByCoordinates} from 'components/customer/BTM/helper/paths';
import {getJoins} from 'components/customer/BTM/helper/joins';

export const pathByShapeMiddleware: Middleware<unknown, AppState> =
    (store) =>
    (next) =>
    (action: PayloadAction<BenchtopType, string, {initBench: boolean}>) => {
        if (action.type == typeSet.type) {
            const initBench = action?.meta.initBench ?? true;

            if (initBench) {
                let dimensions;
                if (action.payload.type == Shape.SQR) {
                    dimensions = [2400, 600];
                } else if (action.payload.type == Shape.ANG) {
                    dimensions = [2400, 600, 1800, 1800, 600, 2400];
                } else {
                    dimensions = [2400, 2400, 600, 1800, 1200, 1800, 600, 2400];
                }

                const {corners: initialCorners, coordinates} = getCoordinates(
                    action.payload.type,
                    dimensions
                );
                const {points, corners} = cutoffs(initialCorners, coordinates);
                const paths = getPathsByCoordinates(points);

                if (action.payload.type != Shape.SQR) {
                    const joins = getJoins(
                        [],
                        corners,
                        dimensions,
                        action.payload.type
                    );
                    store.dispatch(joinsSet(joins));
                } else {
                    store.dispatch(joinsSet([]));
                }

                store.dispatch(dimensionSet(dimensions));
                store.dispatch(cornersSet(corners));
                store.dispatch(coordinatesSet(points));
                store.dispatch(pathsSet(paths));
            }
        }

        return next(action);
    };
