import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {initialValues} from 'components/customer/Room/helpers/Room';

interface ErrorMapping {
    tabName: string;
    fields: Array<keyof Partial<typeof initialValues>>;
}

interface RoomErrorInterface {
    productDefaultsTabErrors: Record<number, ErrorMapping>;
}

const initialState: RoomErrorInterface = {
    productDefaultsTabErrors: {},
};

const roomErrorSlice = createSlice({
    name: 'roomError',
    initialState,
    reducers: {
        setProductDefaultTabErrorMapping: (
            state,
            {payload}: PayloadAction<Record<number, ErrorMapping>>
        ) => {
            state.productDefaultsTabErrors = {
                ...state.productDefaultsTabErrors,
                ...payload,
            };
        },
    },
});

export const {setProductDefaultTabErrorMapping} = roomErrorSlice.actions;
export default roomErrorSlice.reducer;
