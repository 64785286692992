import {APIResponse, AppApi, Pagination} from 'store/customer/api';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {getShape} from 'components/customer/BTM/entity/Shape';
import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {mapImage} from 'components/customer/BTM/helper/mapImage';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {
    mapDisplayName,
    mapHighlightedWord,
    mapFormFactors,
    mapMaterialDimension,
    mapSingleProfileDepth,
    mapButtJoin,
} from 'components/customer/BTM/helper/materialMappers';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Bench} from 'components/customer/BTM/entity/Bench';
import {JoinInfo} from 'components/customer/BTM/entity/JoinInfo';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {
    mapDimension,
    mapMaterialButtJoin,
    mapMaterialChange,
    mapMaterialFormFactor,
    mapOptionBack,
    mapShape,
} from 'components/customer/BTM/helper/mapBenches';
import {BenchtopBrand} from 'components/customer/BTM/entity/BenchtopBrand';
import {BenchtopFinish} from 'components/customer/BTM/entity/BenchtopFinish';

export interface BenchtopMaterialSearchInterface {
    keywords?: string;
    thickness?: number;
    type?: number;
    currentPage?: number;
    pageSize?: number;
    brands?: string[];
    finishes?: string[];
}

export const BTMApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getBenchTypes: builder.query<BenchtopType[], void>({
            query: () => 'benchtop/shapes',
            transformResponse: (data: {
                items: {
                    id: number;
                    identifier: string;
                    name: string;
                    image: string;
                }[];
            }) => {
                return data.items.map((datum) => ({
                    name: datum.name,
                    image: datum.image,
                    id: datum.id,
                    type: getShape(datum.identifier),
                    identifier: datum.identifier,
                }));
            },
            providesTags: ['BTMBenchType'],
        }),
        getMaterialTypes: builder.query<BenchtopMaterialType[], void>({
            query: () =>
                'benchtop/types?related_includes=image&filter_groups=((materials.changes.is_hidden:equals:0))',
            transformResponse: (response: {
                items: BenchtopMaterialType[];
                pagination: Pagination;
            }) => response.items.map(mapImage),
            providesTags: ['BTMMaterialType'],
        }),
        getThickness: builder.query<number[], {materialType: number}>({
            query: ({materialType}) =>
                `benchtop/materials/substrate-thicknesses?type_id=${materialType}`,
            transformResponse: (response: {items: {thickness: number}[]}) =>
                response.items
                    .map((item) => item.thickness)
                    .sort((a, b) => b - a),
            providesTags: (result, error, {materialType}) => [
                {type: 'BTMThickness', id: materialType},
            ],
        }),
        listBTMMaterials: builder.query<
            APIResponse<BenchtopMaterial[]>,
            BenchtopMaterialSearchInterface
        >({
            query: ({
                type,
                thickness,
                brands,
                finishes,
                currentPage = 1,
                pageSize = 1,
            }) => {
                const filterGroups = [];

                if (type) filterGroups.push(`((type_id:equals:${type}))`);
                if (thickness)
                    filterGroups.push(`((thickness:equals:${thickness}))`);
                if (brands && brands.length) {
                    filterGroups.push(
                        `((brand_id:either:${brands.join(',')}))`
                    );
                }
                if (finishes && finishes.length) {
                    filterGroups.push(
                        `((finish_id:either:${finishes.join(',')}))`
                    );
                }

                return `benchtop/materials?current_page=${currentPage}&page_size=${pageSize}&filter_groups=${filterGroups.join(
                    ' AND '
                )}&related_includes=image,type,brand,finish,substrate,formfactors&sort_orders=name ASC`;
            },
            transformResponse: (response: {
                items: BenchtopMaterial[];
                pagination: Pagination;
                group_count: number;
            }) => ({
                data: response.items
                    .map(mapButtJoin)
                    .map(mapDisplayName)
                    .map(mapImage)
                    .map(mapFormFactors)
                    .map(mapMaterialDimension)
                    .map(mapSingleProfileDepth),
                pagination: response.pagination,
                groupCount: response.group_count,
            }),
            providesTags: (
                response,
                error,
                {type, thickness, brands, finishes}
            ) => [
                {
                    type: 'BTMMaterials',
                    typeID: type,
                    thickness,
                    brands,
                    finishes,
                },
            ],
        }),
        searchBTMMaterials: builder.query<
            APIResponse<BenchtopMaterial[]>,
            BenchtopMaterialSearchInterface
        >({
            query: ({
                keywords,
                thickness,
                type,
                brands,
                finishes,
                currentPage = 1,
                pageSize = 10,
            }) => {
                let brandFilters = '';
                let finishFilters = '';

                if (brands && brands.length) {
                    brandFilters = `&brand_ids=${brands.join(',')}`;
                }

                if (finishes && finishes.length) {
                    finishFilters = `&finish_ids=${finishes.join(',')}`;
                }

                return `benchtop/materials/search?keywords=${keywords}${brandFilters}${finishFilters}&type_id=${type}&thickness=${thickness}&current_page=${currentPage}&page_size=${pageSize}`;
            },
            transformResponse: (
                response: {
                    items: BenchtopMaterial[];
                    pagination: Pagination;
                    group_count: number;
                },
                _,
                {keywords}
            ) => ({
                data: response.items
                    .map(mapButtJoin)
                    .map(mapDisplayName)
                    .map(mapHighlightedWord(keywords))
                    .map(mapImage)
                    .map(mapFormFactors)
                    .map(mapMaterialDimension)
                    .map(mapSingleProfileDepth),
                pagination: response.pagination,
                groupCount: response.group_count,
            }),
            providesTags: (
                response,
                error,
                {keywords, type, thickness, brands, finishes}
            ) => [
                {
                    type: 'BTMMaterials',
                    keywords,
                    typeID: type,
                    thickness,
                    brands,
                    finishes,
                },
            ],
        }),
        listEdgeProfiles: builder.query<
            APIResponse<BenchtopEdgeProfile[]>,
            {materialId: number}
        >({
            query: ({materialId}) =>
                `benchtop/edge/profiles?filter_groups=((material.id:equals:${materialId}))`,
            transformResponse: (response: {
                items: BenchtopEdgeProfile[];
                pagination: Pagination;
            }) => ({
                data: response.items.map(mapImage),
                pagination: response.pagination,
            }),
            providesTags: (response, error, {materialId}) => [
                {
                    type: 'BTMEdgeProfiles',
                    id: materialId,
                },
            ],
        }),
        listJoin: builder.query<APIResponse<JoinInfo[]>, void>({
            query: () => 'benchtop/joins',
            transformResponse: (response: {
                items: JoinInfo[];
                pagination: Pagination;
            }) => {
                return {
                    data: response.items.map((item) => {
                        switch (item.identifier) {
                            case 'dog_leg':
                                item.joinType = JoinType.DOG_LEG_MITRE;
                                break;

                            case 'full_mitre':
                                item.joinType = JoinType.FULL_MITRE;
                                break;

                            case 'masons_mitre':
                                item.joinType = JoinType.MASONS_MITRE;
                                break;

                            case 'butt_join':
                                item.joinType = JoinType.BUTT_JOIN;

                                if ('minimum_edge_distance' in item) {
                                    item.minimumEdgeDistance = Number(
                                        item.minimum_edge_distance
                                    );
                                } else {
                                    item.minimumEdgeDistance = 300;
                                }
                                break;
                        }

                        return item;
                    }),
                    pagination: response.pagination,
                };
            },
            providesTags: [{type: 'BTMJoins'}],
        }),
        listBenches: builder.query<APIResponse<Bench[]>, {jobId: number}>({
            query: ({jobId}) =>
                `benchtop/sales/items?related_includes=material.image,variation,shape,type,material,options,material.brand,material.finish,material.substrate,material.formfactors&filter_groups=((job_id:equals:${jobId}))`,
            transformResponse: (response: {
                items: Bench[];
                pagination: Pagination;
            }) => ({
                data: response?.items
                    ? response.items
                          .map((item) => {
                              return {
                                  ...item,
                                  job_benchtop_number: `B${item.job_benchtop_number}`,
                              };
                          })
                          .map(mapMaterialFormFactor)
                          .map(mapOptionBack)
                          .map(mapShape)
                          .map(mapDimension)
                          .map(mapMaterialChange)
                          .map(mapMaterialButtJoin)
                          .map((bench) => ({
                              ...bench,
                              material: mapDisplayName(bench.material),
                          }))
                          .map((bench) => ({
                              ...bench,
                              material: mapMaterialDimension(bench.material),
                          }))
                          .map((bench) => ({
                              ...bench,
                              material: mapSingleProfileDepth(bench.material),
                          }))
                    : [],
                pagination: response.pagination,
            }),
            providesTags: (response, error, {jobId}) => [
                {type: 'BTMBenches', jobId},
            ],
        }),
        getBench: builder.query<Bench, {benchId: number}>({
            query: ({benchId}) =>
                `benchtop/sales/items/${benchId}?related_includes=shape,type,material,options,material.brand,material.finish,material.type,material.substrate,material.formfactors`,
            transformResponse: (response: {data: Bench}) => {
                const bench = [response.data];

                const mappedBench = bench
                    .map(mapMaterialFormFactor)
                    .map(mapOptionBack)
                    .map(mapShape)
                    .map(mapDimension)
                    .map(mapMaterialButtJoin)
                    .map((bench) => ({
                        ...bench,
                        material: mapMaterialDimension(bench.material),
                    }))
                    .map((bench) => ({
                        ...bench,
                        material: mapSingleProfileDepth(bench.material),
                    }));

                return mappedBench[0];
            },
            providesTags: (response, error, {benchId}) => [
                {
                    type: 'BTMBenches',
                    benchId,
                },
            ],
        }),
        deleteBench: builder.mutation<void, {benchId: number}>({
            query: ({benchId}) => ({
                url: `benchtop/sales/items/${benchId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['BTMBenches', 'JobCost'],
        }),
        saveBench: builder.mutation<number, Bench>({
            query: (data) => ({
                url: 'benchtop/sales/items',
                method: 'POST',
                body: data,
            }),
            transformResponse: (response: {data: {id: number}}) =>
                response.data.id,
            invalidatesTags: ['BTMBenches', 'JobCost'],
        }),
        updateBench: builder.mutation<void, {bench: Bench; id: number}>({
            query: ({id, bench}) => ({
                url: `benchtop/sales/items/${id}`,
                method: 'PUT',
                body: bench,
            }),
            invalidatesTags: ['BTMBenches', 'JobCost'],
        }),
        listMaterialBrands: builder.query<BenchtopBrand[], {type: number}>({
            query: ({type}) => {
                let filterGroups = '';
                if (type) {
                    filterGroups = `?filter_groups=(((materials.type.id:equals:${type})))AND((materials.changes.is_hidden:equals:0))AND((materials.is_deleted:equals:0)))AND((is_deleted:equals:false))&sort_orders=name`;
                }

                return `benchtop/brands${filterGroups}`;
            },
            transformResponse: (response: {items: BenchtopBrand[]}) =>
                response.items,
            providesTags: ['BTMMaterialBeands'],
        }),
        listFinishes: builder.query<BenchtopFinish[], {type: number}>({
            query: ({type}) => {
                let filterGroups = '';
                if (type) {
                    filterGroups = `?filter_groups=(((materials.type.id:equals:${type})))AND((materials.changes.is_hidden:equals:0))AND((materials.is_deleted:equals:0)))&sort_orders=name`;
                }

                return `benchtop/finishes${filterGroups}`;
            },
            transformResponse: (response: {items: BenchtopFinish[]}) =>
                response.items,
            providesTags: ['BTMFinishes'],
        }),
    }),
});

export const invalidateBenches = () =>
    BTMApi.util.invalidateTags(['BTMBenches']);

export const {
    useGetBenchTypesQuery,
    useGetMaterialTypesQuery,
    useLazyGetThicknessQuery,
    useListEdgeProfilesQuery,
    useLazySearchBTMMaterialsQuery,
    useLazyListBTMMaterialsQuery,
    useLazyListEdgeProfilesQuery,
    useListJoinQuery,
    useSaveBenchMutation,
    useUpdateBenchMutation,
    useDeleteBenchMutation,
    useListBenchesQuery,
    useGetBenchQuery,
    useLazyListBenchesQuery,
    useLazyListMaterialBrandsQuery,
    useLazyListFinishesQuery,
} = BTMApi;
